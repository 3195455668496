import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { Fade, LinearProgress, SvgIcon } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import React from 'react'
import {
    useGetValueMessageLinkedDataQuery,
    ValueMessageLinkedData,
} from '../../../../shared/queries/useGetValueMessageLinkedData'
import { ReactComponent as LinkIcon } from '../../../../assets/images/hyperlink.svg'
import { EmptyPlaceholder } from '../../../../shared/components/EmptyPlaceholder'
import { FigmaAccentColor } from '../../../../style/colors'

type ValueMessageExpandableRowProps = {
    valueMessageId: string
    isExpanded: boolean
    expandedRowType: keyof Omit<ValueMessageLinkedData, '_id'>
    colorBySubpopulation: FigmaAccentColor
}

const ValueMessageExpandableRow = ({
    isExpanded,
    expandedRowType,
    valueMessageId,
    colorBySubpopulation,
}: ValueMessageExpandableRowProps) => {
    const { data, isLoading } = useGetValueMessageLinkedDataQuery(isExpanded ? valueMessageId : null)

    return (
        <TableCell colSpan={1000} padding="none">
            {isLoading && (
                <Stack color={colorBySubpopulation}>
                    <LinearProgress color="inherit" />
                </Stack>
            )}
            {!isLoading && (
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    {expandedRowType === 'supportingEvidence' && (
                        <Fade in timeout={800}>
                            <Stack
                                direction="column"
                                pl={9}
                                pr={4}
                                py={1.5}
                                borderTop="1px solid"
                                borderBottom="1px solid"
                                borderColor="figma.grayscale.stroke">
                                <Typography variant="16x700" mb={0.5}>
                                    Supporting Evidence
                                </Typography>

                                {!data?.supportingEvidence?.length && (
                                    <Stack direction="column" alignItems="flex-start">
                                        <EmptyPlaceholder text="No data to display" />
                                    </Stack>
                                )}

                                {data?.supportingEvidence.map((evidence, evidenceIndex) => {
                                    // Gather ALL Key Evidence Statements for this Evidence
                                    const matchingKES = data.keyEvidenceStatements?.filter(
                                        (kes) => kes.parentEvidence === evidence._id
                                    )

                                    return (
                                        <Stack
                                            key={evidence._id}
                                            direction="column"
                                            gap={1}
                                            py={1.5}
                                            {...(evidenceIndex !== data.supportingEvidence.length - 1 && {
                                                borderBottom: '1px solid',
                                                borderColor: 'figma.grayscale.stroke',
                                                px: 1.5,
                                                mx: -1.5,
                                            })}>
                                            {/* If NO matching KES, display just the Evidence name */}
                                            {!matchingKES || matchingKES.length === 0 ? (
                                                <Stack direction="row" gap={2}>
                                                    <Typography
                                                        flex={6}
                                                        variant="16x400"
                                                        color="figma.grayscale.textHier2"
                                                        lineHeight="26px">
                                                        {evidenceIndex + 1}. {evidence.name}
                                                    </Typography>

                                                    {/* Link + Citation info (since no KES) */}
                                                    <Stack flex={4} direction="row" gap={0.5} alignItems="center">
                                                        <Tooltip
                                                            title="Open Link"
                                                            enterDelay={600}
                                                            enterNextDelay={600}>
                                                            <IconButton
                                                                size="small"
                                                                component="a"
                                                                href={evidence.link}
                                                                target="_blank">
                                                                <SvgIcon
                                                                    component={LinkIcon}
                                                                    fontSize="small"
                                                                    inheritViewBox
                                                                    color="primary"
                                                                    sx={{ fontSize: '16px' }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Typography
                                                            variant="16x400"
                                                            color="figma.grayscale.tier3"
                                                            lineHeight="26px">
                                                            {evidence.citation || '---'}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            ) : (
                                                /* If we do have one or more KES, list them all */
                                                matchingKES.map((kes, kesIndex) => {
                                                    const lineLabel = `${evidenceIndex + 1}.${kesIndex + 1}`

                                                    return (
                                                        <Stack key={kes._id} direction="row" gap={2}>
                                                            <Typography
                                                                flex={6}
                                                                variant="16x400"
                                                                color="figma.grayscale.textHier2"
                                                                lineHeight="26px">
                                                                {lineLabel} {kes.statement}
                                                            </Typography>

                                                            <Stack
                                                                flex={4}
                                                                direction="row"
                                                                gap={0.5}
                                                                alignItems="center">
                                                                {/* Only show link + citation on the FIRST KES row */}
                                                                {kesIndex === 0 && (
                                                                    <>
                                                                        <Tooltip
                                                                            title="Open Link"
                                                                            enterDelay={600}
                                                                            enterNextDelay={600}>
                                                                            <IconButton
                                                                                size="small"
                                                                                component="a"
                                                                                href={evidence.link}
                                                                                target="_blank">
                                                                                <SvgIcon
                                                                                    component={LinkIcon}
                                                                                    fontSize="small"
                                                                                    inheritViewBox
                                                                                    color="primary"
                                                                                    sx={{ fontSize: '16px' }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        <Typography
                                                                            variant="16x400"
                                                                            color="figma.grayscale.tier3"
                                                                            lineHeight="26px">
                                                                            {evidence.citation || '---'}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                            </Stack>
                                                        </Stack>
                                                    )
                                                })
                                            )}
                                        </Stack>
                                    )
                                })}
                            </Stack>
                        </Fade>
                    )}

                    {expandedRowType === 'evidenceGaps' && (
                        <Fade in timeout={800}>
                            <Stack
                                direction="column"
                                pl={9}
                                pr={4}
                                py={1.5}
                                borderTop="1px solid"
                                borderBottom="1px solid"
                                borderColor="figma.grayscale.stroke">
                                <Typography variant="16x700" mb={0.5}>
                                    Evidence Gaps
                                </Typography>
                                {!data?.evidenceGaps?.length && (
                                    <Stack direction="column" alignItems="flex-start">
                                        <EmptyPlaceholder text="No data to display" />
                                    </Stack>
                                )}
                                {data?.evidenceGaps.map((evidenceGap, index) => (
                                    <Stack
                                        key={evidenceGap._id}
                                        direction="row"
                                        gap={2}
                                        py={1.5}
                                        {...(index !== data.evidenceGaps.length - 1 && {
                                            borderBottom: '1px solid',
                                            borderColor: 'figma.grayscale.stroke',
                                            px: 1.5,
                                            mx: -1.5,
                                        })}>
                                        <Typography
                                            variant="16x400"
                                            color="figma.grayscale.textHier2"
                                            lineHeight="26px">
                                            {index + 1}. {evidenceGap.text}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Fade>
                    )}

                    {expandedRowType === 'ongoingActivities' && (
                        <Fade in timeout={800}>
                            <Stack
                                direction="column"
                                pl={9}
                                pr={4}
                                py={1.5}
                                borderTop="1px solid"
                                borderBottom="1px solid"
                                borderColor="figma.grayscale.stroke">
                                <Typography variant="16x700" mb={0.5}>
                                    Ongoing Activities
                                </Typography>
                                {!data?.ongoingActivities?.length && (
                                    <Stack direction="column" alignItems="flex-start">
                                        <EmptyPlaceholder text="No data to display" />
                                    </Stack>
                                )}
                                {data?.ongoingActivities.map((activity, index) => (
                                    <Stack
                                        key={activity._id}
                                        direction="row"
                                        gap={2}
                                        py={1.5}
                                        {...(index !== data.ongoingActivities.length - 1 && {
                                            borderBottom: '1px solid',
                                            borderColor: 'figma.grayscale.stroke',
                                            px: 1.5,
                                            mx: -1.5,
                                        })}>
                                        <Typography
                                            variant="16x400"
                                            color="figma.grayscale.textHier2"
                                            lineHeight="26px">
                                            {index + 1}. {activity.title}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Fade>
                    )}
                </Collapse>
            )}
        </TableCell>
    )
}

export default ValueMessageExpandableRow
