import Typography from '@mui/material/Typography'
import { SvgIcon } from '@mui/material'
import React from 'react'
import { ReactComponent as GreenCircleIcon } from '../../../../assets/images/green_circle.svg'
import { ReactComponent as OrangeTriangleIcon } from '../../../../assets/images/orange_triangle.svg'
import { ReactComponent as RedSquareIcon } from '../../../../assets/images/red_square.svg'
import Stack from '@mui/material/Stack'

export const ValueMessagesLegend = () => {
    return (
        <Stack direction="column" flex={1}>
            <Typography variant="18x600" sx={{ mb: 1.5 }}>
                Status
            </Typography>
            <Stack direction="column" gap={1.25} p={2} borderRadius={4} bgcolor="figma.grayscale.tier7">
                <Stack direction={{ xs: 'column', md: 'row' }} rowGap={1} columnGap={12}>
                    <Stack direction="row" gap={1.25} alignItems="center">
                        <SvgIcon
                            fontSize="small"
                            component={GreenCircleIcon}
                            inheritViewBox
                            sx={{ fontSize: '16px' }}
                        />
                        <Typography variant="16x500">Supported</Typography>
                    </Stack>
                    <Stack direction="row" gap={1.25} alignItems="center">
                        <SvgIcon fontSize="small" component={RedSquareIcon} inheritViewBox sx={{ fontSize: '16px' }} />
                        <Typography variant="16x500">Aspirational</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" gap={1.25} alignItems="center">
                    <SvgIcon fontSize="small" component={OrangeTriangleIcon} inheritViewBox sx={{ fontSize: '16px' }} />
                    <Typography variant="16x500">Supported-suboptimal</Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}
