import { useMutation, useQueryClient } from 'react-query'
import { useAuth0 } from '../../contexts/auth0-context'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import { useSelector } from 'react-redux'
import {
    ValueNarrative,
    ValueNarrativeCategory,
} from '../../components/ValueNarrativesView/queries/useGetValueNarrativesQuery'

export const useUpdateValueNarrativeSubpopulationMutation = () => {
    const { getTokenSilently } = useAuth0()

    // @ts-expect-error store is not typed properly
    const strategyId = useSelector((state) => state.strategy?._id)
    // @ts-expect-error store is not typed properly
    const organizationId = useSelector((state) => state.strategy?.organizationId)

    const queryClient = useQueryClient()

    const updateValueNarrativeSubpopulationMutation = async (valueNarrative: ValueNarrative) => {
        try {
            const token = await getTokenSilently?.({ audience: 'https://atlas.aesara.com' })

            const { url, method } = endpoints.updateValueNarrative

            const postObject: ValueNarrative = {
                ...valueNarrative,
                valueMessages: valueNarrative.valueMessages.map((message) => message._id),
            }

            const response = await callAPI(url + valueNarrative._id, method, token, postObject)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    return useMutation(updateValueNarrativeSubpopulationMutation, {
        onSuccess: (updatedValueNarrative, valueNarrative) => {
            queryClient.setQueryData(
                ['valueNarratives', strategyId, organizationId],
                (valueNarrativeCategories: ValueNarrativeCategory[]) => {
                    const categories = [...valueNarrativeCategories]

                    const indexOfCategory = categories.findIndex((category) =>
                        category.narratives.some((narrative) => narrative._id === valueNarrative._id)
                    )

                    const indexOfNarrative = categories?.[indexOfCategory].narratives.findIndex(
                        (narrative) => narrative._id === valueNarrative._id
                    )

                    return [
                        ...categories.slice(0, indexOfCategory),
                        {
                            ...categories[indexOfCategory],
                            narratives: [
                                ...categories[indexOfCategory].narratives.slice(0, indexOfNarrative),
                                {
                                    ...categories[indexOfCategory].narratives[indexOfNarrative],
                                    subpopulation: valueNarrative.subpopulation,
                                },
                                ...categories[indexOfCategory].narratives.slice(indexOfNarrative + 1),
                            ],
                        },
                        ...categories.slice(indexOfCategory + 1),
                    ]
                }
            )
        },
    })
}
