import { useCallback, useState } from 'react'

export const useBooleanState = (initialState = false) => {
    const [state, setState] = useState(initialState)

    const handleSetAsTrue = useCallback(() => {
        setState(true)
    }, [])

    const handleSetAsFalse = useCallback(() => {
        setState(false)
    }, [])

    const handleToggle = useCallback(() => {
        setState((prevState) => !prevState)
    }, [])

    return {
        state,
        handleSetAsTrue,
        handleSetAsFalse,
        handleToggle,
    }
}
