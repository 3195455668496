import { FIGMA_ACCENT_COLORS, FigmaAccentColor } from '../../../../../style/colors'
import { useGetSettingsQuery } from '../../../../../shared/queries/useGetSettingsQuery'

export const SUBPOPULATION_COLORS: FigmaAccentColor[] = [
    FIGMA_ACCENT_COLORS.purple,
    FIGMA_ACCENT_COLORS.blue,
    FIGMA_ACCENT_COLORS.magenta,
    FIGMA_ACCENT_COLORS.green,
    FIGMA_ACCENT_COLORS.orange,
    FIGMA_ACCENT_COLORS.salmon,
    FIGMA_ACCENT_COLORS.red,
    FIGMA_ACCENT_COLORS.pink,
    FIGMA_ACCENT_COLORS.yellow,
    FIGMA_ACCENT_COLORS.teal,
    FIGMA_ACCENT_COLORS.neon,
    FIGMA_ACCENT_COLORS.lavender,
]

export const useValueNarrativeColorBySubpopulation = () => {
    const { data } = useGetSettingsQuery()

    const getValueNarrativeColorBySubpopulation = (subpopulation: string): FigmaAccentColor => {
        const subpopulationIndex = (data?.vmSubpopulations || []).indexOf(subpopulation)
        return SUBPOPULATION_COLORS[subpopulationIndex % SUBPOPULATION_COLORS.length] || SUBPOPULATION_COLORS[0]
    }

    return { getValueNarrativeColorBySubpopulation }
}
