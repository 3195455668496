import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { ValueNarrativeCategory } from '../../queries/useGetValueNarrativesQuery'
import { useAddValueNarrativeMutation } from '../../queries/useAddValueNarrativeMutation'
import Grid from '@mui/material/Grid'
import AtlasAIButton from '../../../../shared/components/AtlasAIButton'
import Dropzone from '../../../Buttons/Dropzone'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { Divider, IconButton, Skeleton, stepLabelClasses } from '@mui/material'
import { styled } from '@mui/system'
import StepLabel from '@mui/material/StepLabel'
import axios from 'axios'
import { timeout } from '../../../../utilities/helpers'
import * as _ from 'underscore'
import { AddCircleOutline, CancelOutlined, CheckCircleOutline, Close } from '@mui/icons-material'
import { useGetSettingsQuery } from '../../../../shared/queries/useGetSettingsQuery'

type Props = {
    isOpen: boolean
    handleClose: () => void
    selectedCategory: ValueNarrativeCategory
    categories: ValueNarrativeCategory[]
}

const steps = ['Extracting Text', 'Processing Data', 'Waiting For Server']

const ColorlibStepLabel = styled(StepLabel)(() => ({
    [`& .${stepLabelClasses.label}`]: {
        [`&.${stepLabelClasses.completed}`]: {
            color: 'rgba(0,44,228,0.35)',
        },
        [`&.${stepLabelClasses.active}`]: {
            color: 'rgba(0,44,228,0.83)',
        },
        color: 'rgba(0, 0, 0, 1)',
    },
}))

const SKELETON_COLOR = 'grey.220'

const AddValueNarrativeModal = ({ isOpen, selectedCategory, categories, ...props }: Props) => {
    const [categoryId, setCategoryId] = useState('')
    const [subpopulation, setSubpopulation] = useState('')
    const [name, setName] = useState('')
    const [isAIGeneratedSummary, setIsAIGeneratedSummary] = useState(false)
    const [isAILoading, setIsAILoading] = useState(false)
    const [isAIEnabled, setIsAIEnabled] = React.useState(true)
    const [isAIAutoImportEnabled, setIsAIAutoImportEnabled] = React.useState(true)
    const [didGenerate, setDidGenerate] = React.useState(false)
    const [stepper, setStepper] = React.useState(0)
    const [file, setFile] = useState({})
    const [AIValueNarratives, setAIValueNarratives] = useState([])
    const [approvedNarratives, setApprovedNarratives] = useState([])
    const [rejectedNarratives, setRejectedNarratives] = useState([])

    const getSettingsQuery = useGetSettingsQuery()

    const [narratives, setNarratives] = useState([])

    const handleTextChange = (e, narrativeIndex, messageIndex = null) => {
        const updatedNarratives = [...narratives]
        if (messageIndex !== null) {
            updatedNarratives[narrativeIndex].valueMessages[messageIndex].messageText = e.target.value
        } else {
            updatedNarratives[narrativeIndex].narrativeText = e.target.value
        }
        setNarratives(updatedNarratives)
    }

    const handleRemoveMessage = (narrativeIndex, messageIndex) => {
        const updatedNarratives = [...narratives]
        updatedNarratives[narrativeIndex].valueMessages.splice(messageIndex, 1) // Remove the message
        setNarratives(updatedNarratives) // Update the state
    }

    const handleApprove = (narrativeId) => {
        let temp = [...approvedNarratives]
        temp.push(narrativeId)
        setApprovedNarratives(temp)
        console.log(temp)
    }

    const handleReject = (narrativeId) => {
        let temp = [...rejectedNarratives]
        temp.push(narrativeId)
        setRejectedNarratives(temp)
        console.log(temp)
    }

    useEffect(() => {
        if (isOpen) {
            setCategoryId(selectedCategory._id === 'All' ? '' : selectedCategory._id)
        }
    }, [isOpen])

    useEffect(() => {
        if (isOpen && !subpopulation && getSettingsQuery.data?.vmSubpopulations) {
            setSubpopulation(getSettingsQuery.data.vmSubpopulations[0])
        }
    }, [isOpen, getSettingsQuery.data])

    const handleClose = () => {
        props.handleClose()
        setCategoryId('')
        setSubpopulation('')
        setName('')
        setIsAIGeneratedSummary(false)
        setIsAILoading(false)
        setDidGenerate(false)
        setFile({})
        setRejectedNarratives([])
        setApprovedNarratives([])
        setNarratives([])
    }

    const handleAddMessage = (narrativeIndex) => {
        const newNarratives = [...narratives]
        newNarratives[narrativeIndex].valueMessages.push({
            messageId: `new-${Date.now()}`, // Create a unique ID for the new message
            messageText: '',
            activities: [],
        })
        // Update the state with the new narratives (make sure you have setNarratives in your component)
        setNarratives(newNarratives)
    }

    const { mutate, isLoading } = useAddValueNarrativeMutation({
        onAddValueNarrative: handleClose,
    })

    const handleSave = () => {
        mutate({ categoryId, name, subpopulation })
    }

    async function autoGenerateAISummary(newFile) {
        if (!_.isEmpty(newFile)) {
            setDidGenerate(true)
            setFile(newFile)
            setIsAILoading(true)
            const formData = new FormData()
            formData.append('pptFile', newFile.link)

            setStepper(0)
            automateStepper()
            await axios.post('/api/ppt', formData).then((response) => {
                if (response.data.success) {
                    setNarratives(response.data.message.narratives)
                }
                setIsAILoading(false)
                setIsAIGeneratedSummary(true)
            })

            function automateStepper() {
                timeout(4000).then(() => {
                    setStepper(1)
                    timeout(2000).then(() => setStepper(2))
                })
            }
        }
    }

    const canSave = !!categoryId && !!subpopulation && !!name && !isLoading

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title" maxWidth="md">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h3">Adding a Value Narrative</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <>
                    <Grid container direction="column" spacing={1}>
                        {isAIEnabled && !isAILoading && !isAIGeneratedSummary && (
                            <Grid>
                                <h4 color="#0049EA">{`Import PPT Data with ATLAS AI`}</h4>
                            </Grid>
                        )}
                        <Grid>
                            {/*/!*----------------- FIRST TIME - NO FILE ---------------------------*!/*/}
                            {isAIEnabled && !isAILoading && !didGenerate && (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Dropzone setFile={setFile} generate={autoGenerateAISummary} />
                                    </Grid>
                                </Grid>
                            )}
                            {/*----------------- REVIEWING  ---------------------------*/}
                            {/*{approvedNarratives.length + rejectedNarratives.length === narratives.length && (*/}
                            {/*    <Grid container>*/}
                            {/*        <h3>Approved Narratives</h3>*/}
                            {/*        {approvedNarratives.map((narrative, narrativeIndex) => {*/}
                            {/*            return (*/}
                            {/*                <Box key={narrative.narrativeId} m={4}>*/}
                            {/*                    <Typography variant="h3">*/}
                            {/*                        Value Narrative {narrativeIndex + 1} of {narratives.length}*/}
                            {/*                    </Typography>*/}
                            {/*                    <TextField*/}
                            {/*                        fullWidth*/}
                            {/*                        multiline*/}
                            {/*                        maxRows={2}*/}
                            {/*                        variant="outlined"*/}
                            {/*                        value={narrative.narrativeText}*/}
                            {/*                        onChange={(e) => handleTextChange(e, narrativeIndex)}*/}
                            {/*                        margin="normal"*/}
                            {/*                    />*/}
                            {/*                    <Box display="flex" alignItems="center">*/}
                            {/*                        <Typography variant="h3">Value Messages</Typography>*/}
                            {/*                        <IconButton*/}
                            {/*                            onClick={() => handleAddMessage(narrativeIndex)}*/}
                            {/*                            style={{*/}
                            {/*                                color: 'blue',*/}
                            {/*                                marginLeft: '8px',*/}
                            {/*                            }}>*/}
                            {/*                            <AddCircleOutline />*/}
                            {/*                        </IconButton>*/}
                            {/*                    </Box>*/}
                            {/*                    {narrative.valueMessages.map((message, messageIndex) => (*/}
                            {/*                        <Box key={message.messageId} display="flex" alignItems="center">*/}
                            {/*                            <TextField*/}
                            {/*                                margin="normal"*/}
                            {/*                                fullWidth*/}
                            {/*                                multiline*/}
                            {/*                                maxRows={2}*/}
                            {/*                                variant="outlined"*/}
                            {/*                                value={message.messageText}*/}
                            {/*                                onChange={(e) =>*/}
                            {/*                                    handleTextChange(e, narrativeIndex, messageIndex)*/}
                            {/*                                }*/}
                            {/*                            />*/}
                            {/*                        </Box>*/}
                            {/*                    ))}*/}
                            {/*                    {narrative.valueMessages.length === 0 && <h5> None</h5>}*/}
                            {/*                    <Box display="flex" justifyContent="flex-end" mt={2}>*/}
                            {/*                        <Grid item>*/}
                            {/*                            <IconButton*/}
                            {/*                                onClick={() => handleReject(narrative.narrativeId)}*/}
                            {/*                                style={{ color: 'gray' }}>*/}
                            {/*                                <CancelOutlined />*/}
                            {/*                            </IconButton>*/}
                            {/*                        </Grid>*/}
                            {/*                        <Grid item>*/}
                            {/*                            <IconButton*/}
                            {/*                                onClick={() => handleApprove(narrative.narrativeId)}*/}
                            {/*                                style={{ color: 'blue' }}>*/}
                            {/*                                <CheckCircleOutline />*/}
                            {/*                            </IconButton>*/}
                            {/*                        </Grid>*/}
                            {/*                    </Box>*/}
                            {/*                    <Divider />*/}
                            {/*                </Box>*/}
                            {/*            )*/}
                            {/*        })}*/}
                            {/*    </Grid>*/}
                            {/*)}*/}

                            {/*----------------- GENERATING  ---------------------------*/}
                            {isAILoading && (
                                <>
                                    <Grid container item xs={12}>
                                        <Box sx={{ width: '100%', marginTop: '20px' }}>
                                            <Stepper activeStep={stepper} alternativeLabel>
                                                {steps.map((label) => (
                                                    <Step key={label}>
                                                        <ColorlibStepLabel>{label}</ColorlibStepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        </Box>
                                    </Grid>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            borderRadius: '100%',
                                            zIndex: 1,
                                            backgroundColor: 'background.default',
                                        }}>
                                        <AtlasAIButton size={100} isLoading />
                                    </Box>
                                    <Box sx={{ marginTop: '10px' }}>
                                        <h3>Value Narratives</h3>
                                        <Skeleton
                                            sx={{ bgcolor: SKELETON_COLOR, marginBottom: '10px' }}
                                            variant={'rectangular'}
                                            width={'100%'}
                                            height={80}
                                            animation={'wave'}
                                        />
                                        <h3>Value Messages</h3>
                                        <Skeleton
                                            sx={{ bgcolor: SKELETON_COLOR, marginBottom: '10px' }}
                                            variant={'rectangular'}
                                            width={'100%'}
                                            height={80}
                                            animation={'wave'}
                                        />
                                        <Skeleton
                                            sx={{ bgcolor: SKELETON_COLOR, marginBottom: '10px' }}
                                            variant={'rectangular'}
                                            width={'100%'}
                                            height={80}
                                            animation={'wave'}
                                        />
                                    </Box>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {/*----------------- DATA FIELDS PRE/POST GENERATE  ---------------------------*/}
                    {didGenerate && (
                        <>
                            <Box>
                                {narratives.length > 0 && (
                                    <h3>
                                        Approved {approvedNarratives.length} out of {narratives.length} Value Narratives
                                    </h3>
                                )}
                                {narratives.map((narrative, narrativeIndex) => {
                                    if (
                                        approvedNarratives.includes(narrative.narrativeId) ||
                                        rejectedNarratives.includes(narrative.narrativeId)
                                    ) {
                                        return null // Return null to skip rendering this narrative
                                    }
                                    return (
                                        <Box key={narrative.narrativeId} m={4}>
                                            <Typography variant="h3">
                                                Value Narrative {narrativeIndex + 1} of {narratives.length}
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                multiline
                                                maxRows={2}
                                                variant="outlined"
                                                value={narrative.narrativeText}
                                                onChange={(e) => handleTextChange(e, narrativeIndex)}
                                                margin="normal"
                                            />
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="h3">Value Messages</Typography>
                                                <IconButton
                                                    onClick={() => handleAddMessage(narrativeIndex)}
                                                    style={{
                                                        color: 'blue',
                                                        marginLeft: '8px',
                                                    }}>
                                                    <AddCircleOutline />
                                                </IconButton>
                                            </Box>
                                            {narrative.valueMessages.map((message, messageIndex) => (
                                                <Box key={message.messageId} display="flex" alignItems="center">
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        multiline
                                                        maxRows={2}
                                                        variant="outlined"
                                                        value={message.messageText}
                                                        onChange={(e) =>
                                                            handleTextChange(e, narrativeIndex, messageIndex)
                                                        }
                                                    />
                                                    <IconButton
                                                        onClick={() =>
                                                            handleRemoveMessage(narrativeIndex, messageIndex)
                                                        }
                                                        style={{ color: 'gray', marginLeft: '8px' }}>
                                                        <Close />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                            {narrative.valueMessages.length === 0 && <h5> None</h5>}
                                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                                <Grid item>
                                                    <IconButton
                                                        onClick={() => handleReject(narrative.narrativeId)}
                                                        style={{ color: 'gray' }}>
                                                        <CancelOutlined />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        onClick={() => handleApprove(narrative.narrativeId)}
                                                        style={{ color: 'blue' }}>
                                                        <CheckCircleOutline />
                                                    </IconButton>
                                                </Grid>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    )
                                })}
                            </Box>
                        </>
                    )}
                    {!didGenerate && (
                        <>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="category">Category</InputLabel>
                                <Select
                                    disabled={categories.length === 0 || isLoading}
                                    variant={'outlined'}
                                    label={'Category'}
                                    value={categoryId}
                                    inputProps={{
                                        id: 'category',
                                    }}
                                    onChange={(e) => setCategoryId(e.target.value)}>
                                    {categories
                                        .sort((x, y) => x.order - y.order)
                                        .map((category) => (
                                            <MenuItem key={category._id} value={category._id}>
                                                {category.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <Box mt={1.25}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="category">Subpopulation</InputLabel>
                                    <Select
                                        disabled={getSettingsQuery.isLoading || isLoading}
                                        variant={'outlined'}
                                        label={'Subpopulation'}
                                        value={subpopulation}
                                        inputProps={{
                                            id: 'subpopulation',
                                        }}
                                        onChange={(e) => setSubpopulation(e.target.value)}>
                                        {(getSettingsQuery.data?.vmSubpopulations || []).map((subpopulation) => (
                                            <MenuItem key={subpopulation} value={subpopulation}>
                                                {subpopulation}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={isLoading}
                                    margin="dense"
                                    label="Value Narrative"
                                    placeholder="Enter a new Value Narrative here"
                                    type="text"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    value={name}
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormControl>
                            <Box mt={1} mr={8}>
                                <Typography
                                    // @ts-expect-error theme is not typed properly
                                    variant="subtitle5"
                                    color="figma.grayscale.textHier2">
                                    Please note that you can add a new Narrative without populating the narrative
                                    statement. Simply choose a category & enter a generic statement (e.g. “New
                                    Narrative”)
                                </Typography>
                            </Box>
                            <Box mt={2} mr={8}>
                                <Typography
                                    // @ts-expect-error theme is not typed properly
                                    variant="subtitle5"
                                    color="figma.grayscale.textHier2">
                                    Once you populate the Value Messages, ATLAS AI will generate a statement for you!
                                </Typography>
                            </Box>
                        </>
                    )}
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} disabled={!canSave} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddValueNarrativeModal
