import React, { ReactNode } from 'react'
import Stack from '@mui/material/Stack'
import { SvgIcon, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MUIChip from '@mui/material/Chip'
import { ReactComponent as DeleteIcon } from '../../../assets/images/Close.svg'

type ChipProps = {
    name: ReactNode
    icon?: ReactNode
    color?: string
    backgroundColor?: string
    dense?: boolean
    hoverable?: boolean
    onDelete?: () => void
    onClick?: () => void
}

export const Chip = ({
    name,
    icon,
    color = 'figma.grayscale.textHier2',
    dense = false,
    backgroundColor,
    hoverable = false,
    onDelete,
    onClick,
}: ChipProps) => {
    return (
        <MUIChip
            label={
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={0.5}
                    justifyContent={{ xs: 'space-between', sm: 'unset' }}>
                    {icon}
                    <Typography
                        variant="14x400"
                        color="inherit"
                        sx={{
                            '&:hover': {
                                color: hoverable ? 'figma.primary' : 'inherit',
                            },
                        }}>
                        {name}
                    </Typography>
                    {onDelete && (
                        <IconButton
                            size="small"
                            sx={{ p: 0.5 }}
                            onClick={(e) => {
                                e.stopPropagation() // Stop this click from reaching the Chip
                                onDelete()
                            }}
                            color="inherit">
                            <SvgIcon component={DeleteIcon} inheritViewBox color="inherit" sx={{ fontSize: '16px' }} />
                        </IconButton>
                    )}
                </Stack>
            }
            sx={(theme) => ({
                height: 'auto',
                backgroundColor,
                color,
                py: 0.5,
                px: 1.5,
                '& .MuiChip-label': {
                    p: 0,
                    lineHeight: dense ? 1.1 : 1.5,
                },
                [theme.breakpoints.down('sm')]: {
                    flex: 1,
                    py: 1,
                    '& .MuiChip-label': {
                        flex: 1,
                    },
                },
            })}
            onClick={onClick}
        />
    )
}
