import React, { ReactNode, useEffect, useMemo, useState, MouseEvent } from 'react'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import PicoIcon from '../../icons/PicoIcon'
import ValueCompendiumIcon from '../../icons/ValueCompendiumIcon'
import { ClickAwayListener, Fade, Popper, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import AtlasIcon from '../../icons/AtlasIcon'
import { useSelector } from 'react-redux'
import { ConditionalTooltipWrapper } from '../ConditionalTooltipWrapper'

type ModuleId = 'atlas' | 'pico' | 'valueCompendium'
type Module = { id: ModuleId; icon: ReactNode; name: string; path: string; hidden?: boolean }

// @TODO refactor it

const ModuleSelector = () => {
    const history = useHistory()

    // @ts-expect-error state is not typed
    const isPicoEnabled = useSelector((state) => state?.isPicoEnabled)

    // @ts-expect-error state is not typed
    const isValueCompendiumEnabled = useSelector((state) => !state?.isValueCompendiumEnabled)

    const modules: Module[] = useMemo(
        () => [
            {
                id: 'atlas',
                name: 'ATLAS',
                icon: <AtlasIcon />,
                path: '/',
            },
            {
                id: 'pico',
                name: 'AI PICO Prediction',
                hidden: !isPicoEnabled,
                icon: <PicoIcon />,
                path: '/pico',
            },
            {
                id: 'valueCompendium',
                name: 'Value Compendium',
                hidden: !isValueCompendiumEnabled,
                icon: <ValueCompendiumIcon />,
                path: '/vc',
            },
        ],
        [isPicoEnabled, isValueCompendiumEnabled]
    )

    const visibleModules = modules.filter((module) => !module.hidden)

    const hasMultipleModules = visibleModules.length > 1

    const [selectedModule, setSelectedModule] = useState<Module>(visibleModules[0] as Module)

    useEffect(() => {
        const selectedModule = visibleModules
            .reverse()
            .find((module) => history.location.pathname.startsWith(module.path))

        if (selectedModule) {
            setSelectedModule(selectedModule)
        } else {
            setSelectedModule(visibleModules[0])
        }
    }, [history.location.pathname, modules])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleToggle = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const getModuleButton = (module: Module) => {
        const handleOnClick = (event: MouseEvent<HTMLElement>) => {
            if (hasMultipleModules && module === selectedModule) {
                handleToggle(event)
            } else {
                history.push(module.path)
            }
        }

        return (
            <IconButton
                onClick={handleOnClick}
                sx={{
                    paddingY: 0.5,
                    paddingX: 0.25,
                    borderRadius: '4px',
                    '&:hover': {
                        backgroundColor: hasMultipleModules ? 'figma.primary3' : 'transparent',
                    },
                }}>
                <ConditionalTooltipWrapper
                    condition={!!anchorEl && module !== selectedModule}
                    placement="right"
                    arrow
                    title={
                        <Stack gap={1}>
                            <Typography variant="16x700" color="figma.grayscale.textHier1">
                                {module.name}
                            </Typography>
                        </Stack>
                    }
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: 'figma.grayscale.tier7',
                                px: 2,
                                py: 0.5,
                                borderRadius: '6px',
                                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.20)',
                            },
                        },
                        arrow: {
                            sx: {
                                color: 'figma.grayscale.tier7',
                            },
                        },
                    }}
                    enterDelay={600}
                    enterNextDelay={300}>
                    <Box display="flex">{module.icon}</Box>
                </ConditionalTooltipWrapper>
            </IconButton>
        )
    }

    return (
        <Box>
            {getModuleButton(selectedModule)}
            {hasMultipleModules && (
                <Popper
                    disablePortal
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement="bottom"
                    sx={(theme) => ({ zIndex: theme.zIndex.drawer })}>
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={handleClose}>
                            <Fade {...TransitionProps} in={!!anchorEl} timeout={800} unmountOnExit mountOnEnter>
                                <Stack
                                    direction="column"
                                    mt={1}
                                    py={2}
                                    px={1}
                                    gap={3}
                                    boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.20)"
                                    borderRadius="12px"
                                    bgcolor="figma.grayscale.tier7">
                                    {visibleModules
                                        .filter((module) => module !== selectedModule)
                                        .map((module) => {
                                            return getModuleButton(module as Module)
                                        })}
                                </Stack>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            )}
        </Box>
    )
}

export default ModuleSelector
