import { useMutation, useQueryClient } from 'react-query'
import { useAuth0 } from '../../contexts/auth0-context'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import { useSelector } from 'react-redux'
import { ValueNarrativeCategory } from '../../components/ValueNarrativesView/queries/useGetValueNarrativesQuery'

export const useLockValueNarrativeMutation = () => {
    const { getTokenSilently } = useAuth0()

    // @ts-expect-error store is not typed properly
    const strategyId = useSelector((state) => state.strategy?._id)
    // @ts-expect-error store is not typed properly
    const organizationId = useSelector((state) => state.strategy?.organizationId)

    const queryClient = useQueryClient()

    const lockValueNarrativeMutation = async (valueNarrativeId: string) => {
        try {
            const token = await getTokenSilently?.({ audience: 'https://atlas.aesara.com' })

            const { url, method } = endpoints.lockValueNarrative

            const response = await callAPI(url + valueNarrativeId, method, token)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    return useMutation(lockValueNarrativeMutation, {
        onSuccess: (updatedValueNarrative, valueNarrativeId) => {
            queryClient.setQueryData(
                ['valueNarratives', strategyId, organizationId],
                (valueNarrativeCategories: ValueNarrativeCategory[]) => {
                    const categories = [...valueNarrativeCategories]

                    const indexOfCategory = categories.findIndex((category) =>
                        category.narratives.some((narrative) => narrative._id === valueNarrativeId)
                    )

                    const indexOfNarrative = categories?.[indexOfCategory].narratives.findIndex(
                        (narrative) => narrative._id === valueNarrativeId
                    )

                    return [
                        ...categories.slice(0, indexOfCategory),
                        {
                            ...categories[indexOfCategory],
                            narratives: [
                                ...categories[indexOfCategory].narratives.slice(0, indexOfNarrative),
                                {
                                    ...categories[indexOfCategory].narratives[indexOfNarrative],
                                    locked: updatedValueNarrative.locked,
                                },
                                ...categories[indexOfCategory].narratives.slice(indexOfNarrative + 1),
                            ],
                        },
                        ...categories.slice(indexOfCategory + 1),
                    ]
                }
            )
        },
    })
}
