import React, { useEffect, useState } from 'react'
import '../../style/detailed-view.scss'
import { v4 as uuidv4 } from 'uuid' // or any other method to generate unique IDs
import { useStyles } from '../../style/views'
import { useTheme } from '@mui/styles'
import Divider from '@mui/material/Divider'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Chip from '@mui/material/Chip'
import { Chip as CustomChip } from '../../shared/components/Chip/Chip'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import Alert from '@mui/material/Alert'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import { ReactComponent as PencilIcon } from '../../assets/images/pencil.svg'
import SvgIcon from '@mui/material/SvgIcon'
import CancelIcon from '@mui/icons-material/Cancel'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import CreateLink from '../Buttons/CreateLink'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import GetAppIcon from '@mui/icons-material/GetApp'
import * as _ from 'underscore'
import { uploadFile } from '../../API/PostRequests'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Link from '@mui/material/Link'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Fade from '@mui/material/Fade'
import { useAuth0 } from '../../contexts/auth0-context'
import store from '../../redux/store'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import { useSelector } from 'react-redux'
import SharePoint from './SharePoint/SharePoint'
import { EventType, PublicClientApplication } from '@azure/msal-browser'
import config from './SharePoint/Config'
import Stack from '@mui/material/Stack'
import { ReactComponent as AddCircle, ReactComponent as AddCircleIcon } from '../../assets/images/add-circle.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/Close.svg'
import { ReactComponent as FileIcon } from '../../assets/images/file-icon.svg'
import PhotoIcon from '@mui/icons-material/Photo'
import { DialogContent } from '@mui/material'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const populationOptions = ['X', 'Y', 'Z']
const publicationTypeOptions = ['Manuscript', 'Poster', 'Data on File']

export default function EvidenceDetailedView({
    evidence,
    forceParentRefresh,
    closePanel,
    editing,
    setEditing,
    triggerSave,
    triggerCancel,
    triggerDelete,
    navigationStack,
}) {
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const selectedFile = useSelector((state) => state['selectedSharepointFile'])
    const isDarkMode = useSelector((state) =>
        !state.userRecord ? true : state?.userRecord?.isDarkMode === undefined ? true : state?.userRecord?.isDarkMode
    )

    const theme = useTheme()

    const [isLoaded, setIsLoaded] = useState(false)
    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [value, setValue] = useState(0)
    const [name, setName] = useState('')
    const [newName, setNewName] = useState('')
    const [category, setCategory] = useState('---')
    const [newCategory, setNewCategory] = useState('---')
    const [year, setYear] = useState(0)
    const [newYear, setNewYear] = useState(0)
    const [isInternal, setIsInternal] = useState(false)
    const [newIsInternal, setNewIsInternal] = useState(false)
    const [abstract, setAbstract] = useState('')
    const [newAbstract, setNewAbstract] = useState('')
    const [link, setLink] = useState('')
    const [newLink, setNewLink] = useState('')
    const [s3Name, sets3Name] = useState('')
    const [newS3Name, setNewS3Name] = useState('')
    const [author, setAuthor] = useState('')
    const [newAuthor, setNewAuthor] = useState('')
    const [removeFile, setRemoveFile] = useState(false)
    const [file, setFile] = useState({})
    const [originalFileName, setOriginalFileName] = useState('')
    const [newOriginalFileName, setNewOriginalFileName] = useState('')
    const [linkedValueMessages, setLinkedValueMessages] = useState([])
    const [linkedActivities, setLinkedActivities] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [settings, setSettings] = useState({})
    const [forceRefresh, setForceRefresh] = useState(false)
    const [selectedSharepointFile, setSelectedSharepointFile] = useState({})
    const [externalDevelopment, setExternalDevelopment] = useState(false)
    const [newExternalDevelopment, setNewExternalDevelopment] = useState(false)

    const [uniqueIdentifier, setUniqueIdentifier] = useState('')
    const [newUniqueIdentifier, setNewUniqueIdentifier] = useState('')

    const [population, setPopulation] = useState('X')
    const [newPopulation, setNewPopulation] = useState('X')

    const [publicationType, setPublicationType] = useState('Manuscript')
    const [newPublicationType, setNewPublicationType] = useState('Manuscript')

    const [nickname, setNickname] = useState('')
    const [newNickname, setNewNickname] = useState('')

    const [citation, setCitation] = useState('')
    const [newCitation, setNewCitation] = useState('')

    const [pmid, setPmid] = useState('')
    const [newPmid, setNewPmid] = useState('')

    const [selectedImage, setSelectedImage] = useState(null)

    // Handle opening the popup
    const handleOpenImage = (base64Image) => {
        setSelectedImage(base64Image) // store the Base64 string in state
    }

    // Handle closing the popup
    const handleCloseImage = () => {
        setSelectedImage(null)
    }

    const msalInstance = new PublicClientApplication({
        auth: {
            clientId: config.appId,
            redirectUri: config.redirectUri,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true,
        },
    })

    const accounts = msalInstance.getAllAccounts()
    if (accounts && accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0])
    }

    msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            // Set the active account - this simplifies token acquisition
            const authResult = event.payload
            msalInstance.setActiveAccount(authResult.account)
        }
    })

    useEffect(() => {
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated || !evidence) {
            return
        }
        getEvidenceData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evidence, forceRefresh, isAuthenticated])

    useEffect(() => {
        if (triggerSave === 0) {
            return
        }
        saveEvidence()
    }, [triggerSave])

    useEffect(() => {
        if (triggerCancel === 0) {
            return
        }
        handleCancel()
    }, [triggerCancel])

    useEffect(() => {
        if (triggerDelete === 0) {
            return
        }
        handleDeleteModalOpen()
    }, [triggerDelete])

    function updateState(evidence) {
        setName(evidence.name)
        setNewName(evidence.name)
        setAbstract(evidence.abstract)
        setNewAbstract(evidence.abstract)
        setYear(evidence.year)
        setNewYear(evidence.year)
        setCategory(evidence.category)
        setNewCategory(evidence.category)
        setOriginalFileName(evidence.originalFileName)
        setNewOriginalFileName(evidence.originalFileName)
        let formattedLink = function () {
            if (evidence.link.length === 0) {
                return ''
            } else {
                return evidence.link.includes('http://') || evidence.link.includes('https://')
                    ? evidence.link
                    : 'http://' + evidence.link
            }
        }
        setLink(formattedLink)
        setNewLink(formattedLink)
        sets3Name(evidence.s3Name)
        setNewS3Name(evidence.s3Name)
        setFile({})
        setIsInternal(evidence.internal)
        setNewIsInternal(evidence.internal)
        setAuthor(evidence.author)
        setNewAuthor(evidence.author)
        setSelectedSharepointFile(evidence.sharepointFileLink ? evidence.sharepointFileLink : {})
        setUniqueIdentifier(evidence.uniqueIdentifier)
        setNewUniqueIdentifier(evidence.uniqueIdentifier)
        setPopulation(evidence.population)
        setNewPopulation(evidence.population)
        setPublicationType(evidence.publicationType)
        setNewPublicationType(evidence.publicationType)
        setNickname(evidence.nickname)
        setNewNickname(evidence.nickname)
        setCitation(evidence.citation)
        setNewCitation(evidence.citation)
        setPmid(evidence.pubmedId)
        setNewPmid(evidence.pubmedId)
    }

    async function addLinkedValueMessage(checkedVMs) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        // Find the differences between the checked values and current values
        let toDelete = evidence.linkedValueMessages.filter((x) => !checkedVMs.includes(x))
        toDelete = toDelete.filter((x) => x.length > 5)
        let toAdd = checkedVMs.filter((x) => !evidence.linkedValueMessages.includes(x))
        toAdd = toAdd.filter((x) => x.length > 5)

        // Trigger appropriate linking function for each to enable two-way binding
        // for...of loops execute in sequence. Using 'await' prevents DB versioning  conflicts
        for (let valueMessageId of toAdd) {
            await callAPI(
                endpoints.linkVMToEvidence.url + evidence._id + '/' + valueMessageId,
                endpoints.linkVMToEvidence.method,
                token
            )
        }
        for (let valueMessageId of toDelete) {
            await callAPI(
                endpoints.deleteLinkVMToEvidence.url + evidence._id + '/' + valueMessageId,
                endpoints.deleteLinkVMToEvidence.method,
                token
            )
        }
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function addLinkedActivities(checkedActivities) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        // Find the differences between the checked values and current values
        let toDelete = evidence.linkedActivities.filter((x) => !checkedActivities.includes(x))
        toDelete = toDelete.filter((x) => x.length > 5)
        let toAdd = checkedActivities.filter((x) => !evidence.linkedActivities.includes(x))
        toAdd = toAdd.filter((x) => x.length > 5)

        // Trigger appropriate linking function for each to enable two-way binding
        // for...of loops execute in sequence. Using 'await' prevents DB versioning  conflicts
        for (let activityId of toAdd) {
            await callAPI(
                endpoints.linkActivityToEvidence.url + evidence._id + '/' + activityId,
                endpoints.linkActivityToEvidence.method,
                token
            )
        }
        for (let activityId of toDelete) {
            await callAPI(
                endpoints.deleteLinkActivityToEvidence.url + evidence._id + '/' + activityId,
                endpoints.deleteLinkActivityToEvidence.method,
                token
            )
        }
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function saveEvidence() {
        setEditing(!editing)

        let timestamp = Date.now().toString()
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let tempS3Name = newS3Name
        let tempMimeType = evidence.mimeType
        let tempOriginalFileName = newOriginalFileName
        if (!_.isEmpty(file)) {
            uploadFile(file, timestamp, 'evidence', token)
            tempS3Name = timestamp
            tempMimeType = file.link.type
            tempOriginalFileName = file.link.name
        } else if (removeFile) {
            tempS3Name = ''
            tempMimeType = ''
            tempOriginalFileName = ''
        }

        let newEvidence = {
            name: newName.replace(/\n+/gm, ' '),
            s3Name: tempS3Name,
            link: newLink,
            mimeType: tempMimeType,
            originalFileName: tempOriginalFileName,
            abstract: newAbstract,
            year: newYear,
            category: newCategory,
            internal: newIsInternal,
            author: newAuthor,
            sharepointFileLink: selectedFile,
            strategyId: evidence.strategyId,
            organizationId: evidence.organizationId,
            linkedActivities: evidence.linkedActivities,
            linkedValueMessages: evidence.linkedValueMessages,
            primaryValueMessages: evidence.primaryValueMessages,
            order: evidence.order,
            uniqueIdentifier: newUniqueIdentifier,
            population: newPopulation,
            publicationType: newPublicationType,
            nickname: newNickname,
            citation: newCitation,
            pubmedId: newPmid,
        }
        callAPI(endpoints.updateEvidence.url + evidence._id, endpoints.updateEvidence.method, token, newEvidence)
        setName(newName)
        setAbstract(newAbstract)
        setCategory(newCategory)
        setYear(newYear)
        setLink(newLink)
        setOriginalFileName(!_.isEmpty(file) ? file.link.name : newOriginalFileName)
        setNewOriginalFileName(tempOriginalFileName)
        sets3Name(tempS3Name)
        setNewS3Name(tempS3Name)
        setIsInternal(newIsInternal)
        setAuthor(newAuthor)
        setSelectedSharepointFile(selectedFile)
        setUniqueIdentifier(newUniqueIdentifier)
        setPopulation(newPopulation)
        setPublicationType(newPublicationType)
        setNickname(newNickname)
        setCitation(newCitation)
        setPmid(newPmid)

        let keyEvidenceStatementsBody = {
            statements: newKeyEvidenceStatements,
            deleteIds: keyEvidenceStatementsToDelete,
        }
        callAPI(
            endpoints.handleKeyEvidenceStatements.url,
            endpoints.handleKeyEvidenceStatements.method,
            token,
            keyEvidenceStatementsBody
        )
        setKeyEvidenceStatements(newKeyEvidenceStatements)
        setKeyEvidenceStatementsToDelete([])

        forceParentRefresh(true)
    }

    async function deleteThisEvidence() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        setOpenDeleteModal(false)
        await callAPI(endpoints.deleteEvidence.url + evidence._id, endpoints.deleteEvidence.method, token)
        forceParentRefresh(true)
        closePanel()
    }

    async function getEvidenceData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        updateState(evidence)

        let incomingSettings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (incomingSettings.success) {
            setSettings(incomingSettings.data)
        }

        setIsLoaded(true)

        let linkedVMData = await callAPI(endpoints.getLinkedVMs.url, endpoints.getLinkedVMs.method, token, {
            listOfValueMessages: evidence.linkedValueMessages,
        })
        setLinkedValueMessages(linkedVMData.success ? linkedVMData.data : [])

        let linkedActivityData = await callAPI(
            endpoints.getLinkedActivities.url,
            endpoints.getLinkedActivities.method,
            token,
            { listOfActivities: evidence.linkedActivities }
        )
        setLinkedActivities(linkedActivityData.success ? linkedActivityData.data : [])

        let incomingKeyEvidenceStatementsPre = await callAPI(
            endpoints.getKeyEvidenceStatements.url + evidence._id,
            endpoints.getKeyEvidenceStatements.method,
            token
        )
        if (incomingKeyEvidenceStatementsPre.success) {
            const incomingKeyEvidenceStatements = incomingKeyEvidenceStatementsPre.data.map((stmt) => {
                return {
                    ...stmt,
                    // If the DB statement has an _id, we can also use that as our local id.
                    id: stmt._id || uuidv4(),
                }
            })
            setKeyEvidenceStatements(incomingKeyEvidenceStatements)
            setNewKeyEvidenceStatements(incomingKeyEvidenceStatements)
        }
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    const linkButtonTabIndexMapping = {
        0: 'vm',
        1: 'activity',
    }

    function onFileChange(e) {
        setFile({ link: e.target.files[0] })
    }

    function handleCancel() {
        setNewName(name)
        setNewYear(year)
        setNewCategory(category)
        setNewAbstract(abstract)

        let formattedLink = function () {
            if (link.length === 0) {
                return ''
            } else {
                return link.includes('http://') || link.includes('https://') ? link : 'http://' + link
            }
        }
        setNewOriginalFileName(originalFileName)
        setNewS3Name(s3Name)
        setNewLink(formattedLink)
        sets3Name(s3Name)
        setFile({})
        setEditing(false)
        setIsInternal(isInternal)
        setNewAuthor(author)
        setSelectedSharepointFile({})
        setNewUniqueIdentifier(uniqueIdentifier)
        setNewPopulation(population)
        setNewPublicationType(publicationType)
        setNewNickname(nickname)
        setNewCitation(citation)
        setNewPmid(pmid)
        setNewKeyEvidenceStatements(keyEvidenceStatements)
        setKeyEvidenceStatementsToDelete([])
    }

    function handleDeleteModalClose() {
        setOpenDeleteModal(false)
    }

    function handleDeleteModalOpen() {
        setOpenDeleteModal(true)
    }

    function addToStack() {
        let arr = Array.from(navigationStack)
        arr.push({
            path: '/evidence',
            data: evidence,
        })
        return arr
    }

    // const keyEvidenceStatementEchoCategories = ['Economic', 'Clinical', 'Humanistic']
    const [keyEvidenceStatements, setKeyEvidenceStatements] = useState([])
    const [newKeyEvidenceStatements, setNewKeyEvidenceStatements] = useState([])
    const [keyEvidenceStatementsToDelete, setKeyEvidenceStatementsToDelete] = useState([])

    const handleAddKeyEvidenceStatement = () => {
        setNewKeyEvidenceStatements((prev) => [
            ...prev,
            {
                id: uuidv4(), // Local identifier for the UI
                statement: '',
                figures: [],
                // category: '',
                parentEvidence: evidence._id,
                organizationId: store.getState().strategy.organizationId,
                strategyId: store.getState().strategy._id,
            },
        ])
    }

    const handleRemoveKeyEvidenceStatement = (localId) => {
        setNewKeyEvidenceStatements((prev) => {
            const statementToRemove = prev.find((stmt) => stmt.id === localId)
            if (!statementToRemove) return prev

            // If it has a MongoDB _id, this is an existing statement => mark for deletion
            if (statementToRemove._id) {
                setKeyEvidenceStatementsToDelete((prevDeletes) => [...prevDeletes, statementToRemove._id])
            }

            // Filter it out from state
            return prev.filter((stmt) => stmt.id !== localId)
        })
    }

    // Handle file selection for a specific key evidence statement
    const handleFileSelect = (e, localId) => {
        // Convert the FileList into an array
        const allSelectedFiles = Array.from(e.target.files)

        // Filter out only PNG, JPG, or JPEG files
        const imageFiles = allSelectedFiles.filter((file) => {
            const extension = file.name.split('.').pop().toLowerCase()
            return extension === 'png' || extension === 'jpg' || extension === 'jpeg'
        })

        // Convert each file to Base64 using a Promise-based approach
        const fileReaders = imageFiles.map(
            (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = (error) => reject(error)
                })
        )

        // Once all files have been converted, update the state
        Promise.all(fileReaders)
            .then((base64Array) => {
                setNewKeyEvidenceStatements((prevStatements) => {
                    // Map through existing statements and add the new Base64 images
                    const updatedStatements = prevStatements.map((statement) =>
                        statement.id === localId
                            ? {
                                  ...statement,
                                  figures: [...(statement.figures || []), ...base64Array],
                              }
                            : statement
                    )

                    // Print the updated statements
                    console.log('Key Evidence Statements after file selection:', updatedStatements)

                    return updatedStatements
                })
            })
            .catch((error) => {
                console.error('Error converting files to Base64:', error)
            })
    }

    // Handle file removal for a specific key evidence statement
    const handleRemoveFile = (fileToRemove, localId) => {
        setNewKeyEvidenceStatements((prevStatements) =>
            prevStatements.map((statement) =>
                statement.id === localId
                    ? {
                          ...statement,
                          figures: (statement.figures || []).filter((file) => file !== fileToRemove),
                      }
                    : statement
            )
        )
    }

    // // Handle category selection for a specific key evidence statement
    // const handleCategoryChange = (e, statementId) => {
    //     const category = e.target.value
    //     setKeyEvidenceStatements((prevStatements) =>
    //         prevStatements.map((statement) => (statement.id === statementId ? { ...statement, category } : statement))
    //     )
    // }

    const handleTextChange = (e, statementId) => {
        const text = e.target.value
        setNewKeyEvidenceStatements((prevStatements) =>
            prevStatements.map((statementObj) =>
                statementObj.id === statementId ? { ...statementObj, statement: text } : statementObj
            )
        )
    }

    // Render the file chips for a specific statement
    const renderFileChips = (statementId) => {
        const statement = newKeyEvidenceStatements.find((stmt) => stmt.id === statementId)
        return statement?.figures.map((file, index) => (
            <CustomChip
                key={index}
                backgroundColor="figma.primary3"
                icon={<PhotoIcon inheritViewBox fontSize="small" sx={{ mr: 0.5, mb: 0.5 }} />}
                name={`Figure ${index + 1}`}
                variant="outlined"
                onDelete={() => handleRemoveFile(file, statementId)}
                onClick={() => handleOpenImage(file)}
            />
        ))
    }

    return (
        <Fade in={isLoaded} timeout={800}>
            <Grid container direction="column" item sx={{ backgroundColor: isDarkMode ? '#363943' : 'white' }}>
                <Dialog
                    open={openDeleteModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDeleteModalClose}
                    aria-labelledby="alert-dialog-slide-title6"
                    aria-describedby="alert-dialog-slide-description6">
                    <DialogTitle id="alert-dialog-slide-title6">
                        <Typography variant="h4">Are you sure you want to delete this Evidence?</Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDeleteModalClose} variant="outlined-sm-button">
                            Cancel
                        </Button>
                        <Button onClick={deleteThisEvidence} variant="contained-sm-button">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid container item direction="column">
                    {editing ? (
                        <Grid
                            container
                            item
                            direction="column"
                            sx={{
                                width: '100%',
                                ml: 0,
                                pt: 4,
                                pr: 3,
                                pb: 4,
                                borderBottomLeftRadius: '24px',
                                borderBottomRightRadius: '24px',
                                backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                            }}
                            spacing={3}>
                            <Grid container item direction="column">
                                <Grid item sx={{ mb: 1 }}>
                                    <Typography variant="title2">
                                        <strong>Title</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="edit-evidence"
                                        multiline
                                        variant="outlined"
                                        minRows={4}
                                        placeholder={name}
                                        value={newName}
                                        onFocus={(event) => {
                                            event.target.setAttribute('autocomplete', 'off')
                                        }}
                                        onChange={(e) => setNewName(e.target.value)}
                                        fullWidth
                                        sx={{
                                            '.MuiInputBase-root': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid container item xs={6} direction="column">
                                    <Grid item sx={{ mb: 1 }}>
                                        <Typography variant="title2">
                                            <strong>Unique Study Identifier</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            value={newUniqueIdentifier}
                                            onChange={(e) => setNewUniqueIdentifier(e.target.value)}
                                            fullWidth
                                            variant="outlined"
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    borderRadius: '12px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={6} direction="column">
                                    <Grid item sx={{ mb: 1 }}>
                                        <Typography variant="title2">
                                            <strong>PubMed ID</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            value={newPmid}
                                            onChange={(e) => setNewPmid(e.target.value)}
                                            fullWidth
                                            variant="outlined"
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    borderRadius: '12px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item direction="column">
                                <Grid item sx={{ mb: 1 }}>
                                    <Typography variant="title2">
                                        <strong>Nickname</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        value={newNickname}
                                        onChange={(e) => setNewNickname(e.target.value)}
                                        fullWidth
                                        variant="outlined"
                                        sx={{
                                            '.MuiInputBase-root': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="column">
                                <Grid item sx={{ mb: 1 }}>
                                    <Typography variant="title2">
                                        <strong>Key Points & Disclaimers</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="edit-abstract"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        placeholder={abstract}
                                        value={newAbstract}
                                        onFocus={(event) => {
                                            event.target.setAttribute('autocomplete', 'off')
                                        }}
                                        onChange={(e) => setNewAbstract(e.target.value)}
                                        fullWidth
                                        sx={{
                                            '.MuiInputBase-root': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="column">
                                <Grid item sx={{ mb: 1 }}>
                                    <Typography variant="title2">
                                        <strong>Citation</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        value={newCitation}
                                        onChange={(e) => setNewCitation(e.target.value)}
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        sx={{
                                            '.MuiInputBase-root': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="column">
                                <Grid item sx={{ mb: 1 }}>
                                    <Typography variant="title2">
                                        <strong>Author(s)</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="edit-evidence"
                                        variant="outlined"
                                        placeholder={author}
                                        value={newAuthor}
                                        onFocus={(event) => {
                                            event.target.setAttribute('autocomplete', 'off')
                                        }}
                                        onChange={(e) => setNewAuthor(e.target.value)}
                                        fullWidth
                                        sx={{
                                            '.MuiInputBase-root': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid container item xs={6} direction="column">
                                    <Grid item sx={{ mb: 1 }}>
                                        <Typography variant="title2">
                                            <strong>Population</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <Select
                                                value={newPopulation}
                                                onChange={(e) => setNewPopulation(e.target.value)}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderRadius: '12px',
                                                    },
                                                }}>
                                                {populationOptions.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={6} direction="column">
                                    <Grid item sx={{ mb: 1 }}>
                                        <Typography variant="title2">
                                            <strong>Publication Type</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <Select
                                                value={newPublicationType}
                                                onChange={(e) => setNewPublicationType(e.target.value)}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderRadius: '12px',
                                                    },
                                                }}>
                                                {publicationTypeOptions.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item spacing={3} alignItems="center" justifyContent="space-between">
                                <Grid container item xs direction="column">
                                    <Grid item sx={{ mb: 1 }}>
                                        <Typography variant="title2">
                                            <strong>Year</strong>
                                        </Typography>
                                    </Grid>
                                    <TextField
                                        id="edit-year"
                                        value={newYear}
                                        variant="outlined"
                                        placeholder={year}
                                        type="number"
                                        onFocus={(event) => {
                                            event.target.setAttribute('autocomplete', 'off')
                                        }}
                                        fullWidth
                                        sx={{
                                            '.MuiInputBase-root': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                        onChange={(e) => setNewYear(Number.parseInt(e.target.value))}
                                    />
                                </Grid>
                                <Grid container item xs direction="column">
                                    <Grid item sx={{ mb: 1 }}>
                                        <Typography variant="title2">
                                            <strong>Category</strong>
                                        </Typography>
                                    </Grid>
                                    <FormControl>
                                        <Select
                                            inputProps={{
                                                id: 'outlined-age-native-simple1',
                                            }}
                                            value={newCategory}
                                            onChange={(e) => setNewCategory(e.target.value)}
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderRadius: '12px',
                                                },
                                            }}>
                                            <MenuItem value={'---'}>---</MenuItem>
                                            {settings.evidenceCategories.map((category, i) => (
                                                <MenuItem value={category} key={'ev' + category + i}>
                                                    {category}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs="auto" direction="column" sx={{ mt: -3 }}>
                                    <Grid item>
                                        <Typography variant="title2">
                                            <strong>Study Type</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid container item alignItems="center">
                                        <Grid item>Internal</Grid>
                                        <Grid item>
                                            <Switch
                                                checked={newIsInternal}
                                                onChange={(e) => setNewIsInternal(e.target.checked)}
                                                name="Internal/External"
                                            />
                                        </Grid>
                                        <Grid item>External</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Stack direction="column" gap={2}>
                                    <Stack direction="row" gap={1} alignItems="center">
                                        <Typography variant="title2">
                                            <strong>Key Evidence Statements</strong>
                                        </Typography>
                                        <Tooltip title="Add a new Key Evidence Statement">
                                            <IconButton onClick={handleAddKeyEvidenceStatement}>
                                                <SvgIcon
                                                    component={AddCircleIcon}
                                                    color="primary"
                                                    inheritViewBox
                                                    fontSize="medium"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                    <Stack direction="column" gap={2} pl={3}>
                                        {newKeyEvidenceStatements.map((keyEvidenceStatement, index) => (
                                            <Stack
                                                key={keyEvidenceStatement.id}
                                                direction="row"
                                                alignItems="flex-start"
                                                gap={1}>
                                                <Stack
                                                    flex={1}
                                                    direction="column"
                                                    gap={1.5}
                                                    sx={{
                                                        '&:before': {
                                                            content: `"${index + 1}."`,
                                                            position: 'absolute',
                                                            left: '26px',
                                                            color: 'figma.grayscale.tier4',
                                                        },
                                                    }}>
                                                    <TextField
                                                        id={'key-evidence-statement' + keyEvidenceStatement.id}
                                                        multiline
                                                        variant="outlined"
                                                        minRows={2}
                                                        placeholder={keyEvidenceStatement.statement}
                                                        value={keyEvidenceStatement.statement}
                                                        onFocus={(event) => {
                                                            event.target.setAttribute('autocomplete', 'off')
                                                        }}
                                                        onChange={(e) => handleTextChange(e, keyEvidenceStatement.id)}
                                                        fullWidth
                                                        sx={{
                                                            '.MuiInputBase-root': {
                                                                borderRadius: '12px',
                                                            },
                                                        }}
                                                    />
                                                    <Stack direction="row" gap={1} alignItems="center">
                                                        <Tooltip title="Attach a file">
                                                            <IconButton component="label">
                                                                <input
                                                                    type="file"
                                                                    multiple
                                                                    hidden
                                                                    onChange={(e) =>
                                                                        handleFileSelect(e, keyEvidenceStatement.id)
                                                                    }
                                                                />
                                                                <SvgIcon
                                                                    component={FileIcon}
                                                                    inheritViewBox
                                                                    fontSize="medium"
                                                                    sx={{ color: 'figma.primary', fontSize: '24px' }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Stack direction="row" gap={1.5} flexWrap="wrap">
                                                            {renderFileChips(keyEvidenceStatement.id)}
                                                        </Stack>
                                                    </Stack>
                                                    {/*<FormControl fullWidth>*/}
                                                    {/*    <InputLabel htmlFor="category">ECHO Category</InputLabel>*/}
                                                    {/*    <Select*/}
                                                    {/*        variant="outlined"*/}
                                                    {/*        label="ECHO Category"*/}
                                                    {/*        value={keyEvidenceStatement.category}*/}
                                                    {/*        inputProps={{*/}
                                                    {/*            id: 'category',*/}
                                                    {/*        }}*/}
                                                    {/*        onChange={(e) =>*/}
                                                    {/*            handleCategoryChange(e, keyEvidenceStatement.id)*/}
                                                    {/*        }*/}
                                                    {/*        sx={{*/}
                                                    {/*            '& .MuiOutlinedInput-notchedOutline': {*/}
                                                    {/*                borderRadius: '12px',*/}
                                                    {/*            },*/}
                                                    {/*        }}>*/}
                                                    {/*        {keyEvidenceStatementEchoCategories.map((category) => (*/}
                                                    {/*            <MenuItem key={category} value={category}>*/}
                                                    {/*                {category}*/}
                                                    {/*            </MenuItem>*/}
                                                    {/*        ))}*/}
                                                    {/*    </Select>*/}
                                                    {/*</FormControl>*/}
                                                </Stack>
                                                <Tooltip title="Remove Key Evidence Statement">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleRemoveKeyEvidenceStatement(keyEvidenceStatement.id)
                                                        }>
                                                        <SvgIcon
                                                            component={CloseIcon}
                                                            inheritViewBox
                                                            fontSize="medium"
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item sx={{ mb: -2 }}>
                                <Typography variant="title2">
                                    <strong>Link</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    id="edit-link"
                                    placeholder={link}
                                    value={newLink}
                                    variant="outlined"
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    onChange={(e) => setNewLink(e.target.value)}
                                    fullWidth
                                    sx={{
                                        '.MuiInputBase-root': {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid container item>
                                <Grid item sx={{ mb: 1 }}>
                                    <Typography variant="title2">Original File</Typography>
                                </Grid>
                                <Grid container item spacing={2}>
                                    <Grid item>
                                        <Typography vairant="body1">
                                            {newOriginalFileName && newOriginalFileName.length > 0
                                                ? newOriginalFileName
                                                : 'No File Attached'}
                                        </Typography>
                                    </Grid>
                                    {newOriginalFileName && newOriginalFileName.length !== 0 && (
                                        <Grid item>
                                            <Tooltip title={'Remove current uploaded file'}>
                                                <CancelIcon
                                                    onClick={() => {
                                                        setFile({})
                                                        setRemoveFile(true)
                                                        setNewOriginalFileName('')
                                                        setNewS3Name('')
                                                    }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item direction="column">
                                <Grid item sx={{ mb: 1 }}>
                                    <Typography variant="title2">New File</Typography>
                                </Grid>
                                <Grid item>
                                    <input type="file" onChange={onFileChange} />
                                </Grid>
                            </Grid>
                            <Grid container item>
                                {/*<FilePicker />*/}
                                <SharePoint pca={msalInstance} />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container xs sx={{ backgroundColor: isDarkMode ? '#363943' : 'white', mr: -1 }}>
                            <Accordion defaultExpanded square variant="rounded-bottom" sx={{ width: '100%' }}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                        pl: 3,
                                        pt: 1.5,
                                        pb: 0.5,
                                        borderBottomLeftRadius: '24px',
                                        borderBottomRightRadius: '24px',
                                    }}
                                    expandIcon={
                                        <IconButton sx={{ mr: 1, mb: 1.5 }}>
                                            <ExpandMoreIcon color="primary" />
                                        </IconButton>
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid container alignItems="center" wrap="nowrap" spacing={4}>
                                        <Grid container item direction="column" spacing={1}>
                                            <Grid item>
                                                <Typography variant="h5">Title:</Typography>
                                            </Grid>
                                            <Grid container item direction="row" spacing={1}>
                                                <Grid item xs={10} sx={{ mb: 1 }}>
                                                    <Typography variant="h2">{name}</Typography>
                                                </Grid>
                                                <Grid // Adding an invisible grid with chip to maintain similar appearance as the VMs and Activities pages
                                                    item>
                                                    <Chip
                                                        sx={{
                                                            mb: 1,
                                                            backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                                        }}
                                                        icon={<SvgIcon inheritViewBox fontSize="inherit" />}
                                                        label={<Typography variant="chip"></Typography>}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction="row">
                                        <Grid
                                            container
                                            item
                                            spacing={3}
                                            xs={12}
                                            lg={7}
                                            sx={{ backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6', ml: -2, mb: 4 }}>
                                            <Grid container item xs={6}>
                                                <Grid item>
                                                    <Typography variant="title2">Unique Study Identifier:</Typography>
                                                </Grid>
                                                <Grid item sx={{ ml: 1 }}>
                                                    <Typography variant="body1">
                                                        {uniqueIdentifier || 'No identifier provided'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={6}>
                                                <Grid item>
                                                    <Typography variant="title2">PubMed ID:</Typography>
                                                </Grid>
                                                <Grid item sx={{ ml: 1 }}>
                                                    <Typography variant="body1">
                                                        {pmid || 'No PubMed ID provided'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            spacing={3}
                                            xs={12}
                                            lg={7}
                                            sx={{ backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6', ml: -2 }}>
                                            <Grid container item direction="column" sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <Typography variant="title2">Key Points & Disclaimers:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" sx={{ whiteSpace: 'break-spaces' }}>
                                                        {abstract}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="title2">Nickname:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1">
                                                        {nickname || 'No nickname provided'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item direction="column">
                                                <Grid item>
                                                    <Typography variant="title2">Citation:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1">
                                                        {citation || 'No citation provided'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item direction="column">
                                                <Grid item>
                                                    <Typography variant="title2">Author(s):</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1">{author}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item spacing={2} sx={{ mb: 4 }}>
                                                <Grid container item xs={6}>
                                                    <Grid item>
                                                        <Typography variant="title2">Population:</Typography>
                                                    </Grid>
                                                    <Grid item sx={{ ml: 1 }}>
                                                        <Chip
                                                            label={<Typography variant="chip">{population}</Typography>}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={6}>
                                                    <Grid item>
                                                        <Typography variant="title2">Publication Type:</Typography>
                                                    </Grid>
                                                    <Grid item sx={{ ml: 1 }}>
                                                        <Chip
                                                            label={
                                                                <Typography variant="chip">
                                                                    {publicationType}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={3}
                                            xs={12}
                                            lg={7}
                                            sx={{
                                                // This margin-left will shift all content to the left
                                                ml: -2,
                                                backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                                pb: 3, // so bottom isn't clipped
                                            }}>
                                            {/* Title */}
                                            <Grid item xs={12}>
                                                <Typography variant="title2" gutterBottom>
                                                    Key Evidence Statements
                                                </Typography>
                                            </Grid>

                                            {/* Statements */}
                                            {keyEvidenceStatements.map((stmt, index) => (
                                                <Grid key={stmt._id || index} container item xs={12} spacing={0.5}>
                                                    {/* First row: index + truncated text */}
                                                    <Grid item container alignItems="flex-start">
                                                        {/* Index */}
                                                        <Grid item>
                                                            <Typography variant="body2" sx={{ mr: 1 }}>
                                                                {index + 1}.
                                                            </Typography>
                                                        </Grid>

                                                        {/* Truncated text */}
                                                        <Grid item xs>
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    display: '-webkit-box',
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: 'vertical',
                                                                    overflow: 'hidden',
                                                                }}>
                                                                {stmt.statement}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    {/* Second row: PhotoIcons for figures */}
                                                    {Array.isArray(stmt.figures) && stmt.figures.length > 0 && (
                                                        <Grid item xs={12} sx={{ ml: '2rem', mt: 0.5 }}>
                                                            {stmt.figures.map((figure, figIndex) => (
                                                                <PhotoIcon
                                                                    key={figIndex}
                                                                    fontSize="medium"
                                                                    sx={{ mr: 1, cursor: 'pointer' }}
                                                                    onClick={() => handleOpenImage(figure)}
                                                                />
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            ))}
                                            {/* Dialog for image preview */}
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            direction="column"
                                            spacing={3}
                                            xs={11}
                                            lg={4}
                                            sx={{ backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6', ml: -2 }}>
                                            <Grid container item direction="column" spacing={2} xs={5}>
                                                <Grid container item spacing={1} direction="column">
                                                    <Grid item>
                                                        <Typography variant="title2">Year:</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>{year ? year : 'No year to display'}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item spacing={1} direction="column">
                                                    <Grid item>
                                                        <Typography variant="title2">Category:</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            <Chip
                                                                label={
                                                                    <Typography variant="chip">{category}</Typography>
                                                                }
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item spacing={1} direction="column">
                                                    <Grid item>
                                                        <Typography variant="title2">Study Type:</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Chip
                                                            label={
                                                                <Typography variant="chip">
                                                                    {isInternal ? 'External' : 'Internal'}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item direction="column" spacing={2}>
                                                    {
                                                        <Grid container item direction="column">
                                                            <Grid item>
                                                                <Typography variant="title2">Link(s):</Typography>
                                                            </Grid>
                                                            <Grid item sx={{ mt: 1 }}>
                                                                <Chip
                                                                    label={
                                                                        <Link
                                                                            underline="none"
                                                                            href={link}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer">
                                                                            {link.length > 0
                                                                                ? link.length > 15
                                                                                    ? link.slice(0, 55) + '...'
                                                                                    : link
                                                                                : 'No link to display'}
                                                                        </Link>
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {
                                                        <Grid container item direction="column">
                                                            <Grid item>
                                                                <Typography variant="title2">File(s):</Typography>
                                                            </Grid>
                                                            <Grid item sx={{ mt: 1, mb: 2 }}>
                                                                {evidence.s3Name.length ? (
                                                                    <Chip
                                                                        label={
                                                                            <Link
                                                                                underline="none"
                                                                                href={
                                                                                    (process.env.REACT_APP_BACKEND_LINK
                                                                                        ? process.env
                                                                                              .REACT_APP_BACKEND_LINK
                                                                                        : '') +
                                                                                    '/api/file/' +
                                                                                    evidence._id
                                                                                }
                                                                                target="_"
                                                                                rel="noopener noreferrer">
                                                                                <GetAppIcon />
                                                                            </Link>
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <Typography variant="subtitle2">
                                                                        No File Uploaded
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {selectedSharepointFile && !_.isEmpty(selectedSharepointFile) && (
                                                        <Grid container item direction="column">
                                                            <Grid item>
                                                                <Typography variant="title2">
                                                                    OneDrive File(s):
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <a href={selectedSharepointFile.webUrl}>
                                                                    <Typography variant="subtitle1" color="primary">
                                                                        {selectedSharepointFile.name}
                                                                    </Typography>
                                                                </a>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}
                </Grid>

                <Grid
                    container
                    item
                    direction="column"
                    sx={{ backgroundColor: isDarkMode ? '#363943' : 'white', p: 4, pr: 5 }}>
                    <Grid item sx={{ mb: 2.2, pl: 2, pt: 1 }}>
                        <Typography variant="h2">Linked data</Typography>
                    </Grid>
                    <Grid container item alignItems="center" justifyContent="space-between">
                        <Grid item xs={6} sx={{ pl: 2, mb: 0 }}>
                            <Tabs
                                value={value}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                aria-label="full width tabs example">
                                <Tab
                                    label={store.getState().settings?.displayNames?.valueMessages || 'Loading'}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label={store.getState().settings?.displayNames?.activities || 'Loading'}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Grid>
                        <Grid container item xs="auto" sx={{ mr: 2, mt: 2 }}>
                            {!snapshotMode && !userReadonly && (
                                <CreateLink
                                    srcPage={'evidence'}
                                    sx={{ mt: 2 }}
                                    id={linkButtonTabIndexMapping[value]}
                                    listItems={value === 0 ? linkedValueMessages : linkedActivities}
                                    update={value === 0 ? addLinkedValueMessage : addLinkedActivities}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item xs justifyContent="space-between" sx={{ pt: 1 }}>
                        <TabPanel
                            value={value}
                            index={0}
                            dir={theme.direction}
                            style={{ marginLeft: 8, marginRight: -8, marginTop: 15, width: '100%' }}>
                            {linkedValueMessages && linkedValueMessages.length ? (
                                linkedValueMessages.map((vm, i) => (
                                    <Link
                                        component={RouterLink}
                                        underline="none"
                                        color="secondary"
                                        key={'linkedVM' + i}
                                        to={{
                                            pathname: '/value-narratives',
                                            autoOpenData: {
                                                isAutoOpenPanel: true,
                                                autoOpenData: vm,
                                                previousDataStack: addToStack(),
                                            },
                                        }}>
                                        <Grid
                                            container
                                            item
                                            wrap="nowrap"
                                            spacing={1}
                                            xs
                                            alignItems="flex-start"
                                            sx={{
                                                backgroundColor: isDarkMode ? 'rgba(0, 0, 0, .3)' : '#F6F6F7',
                                                mb: 3,
                                                pb: 2,
                                                borderRadius: '16px',
                                            }}>
                                            <Grid item xs={0} sx={{ ml: 2, mt: 1 }}>
                                                <Typography variant="body1">{i + 1}.</Typography>
                                            </Grid>
                                            <Grid container item direction="column" sx={{ mt: 1 }}>
                                                <Grid item xs sx={{ pr: 1 }}>
                                                    <Typography variant="body1">{vm.name}</Typography>
                                                </Grid>
                                                <Grid item xs zeroMinWidth sx={{ mt: 1 }}>
                                                    <Chip
                                                        sx={{ backgroundColor: isDarkMode ? 'black' : '#EBF1FD' }}
                                                        label={
                                                            <Typography variant="chip">{vm.category.name}</Typography>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1} sx={{ mr: -2.7, mt: 2 }}>
                                                <ArrowForwardIosIcon fontSize="small" color="primary" />
                                            </Grid>
                                        </Grid>
                                    </Link>
                                ))
                            ) : (
                                <Alert
                                    icon={<LinkOffIcon fontSize="inherit" />}
                                    severity="info"
                                    sx={{ borderRadius: '12px', mt: -1.9, ml: -1, mr: 1 }}>
                                    {`No Linked ${store.getState().settings?.displayNames?.valueMessages || 'Loading'}`}
                                </Alert>
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction} style={{ marginTop: 7, width: '100%' }}>
                            {linkedActivities && linkedActivities.length ? (
                                linkedActivities.map((act, i) => (
                                    <Link
                                        component={RouterLink}
                                        underline="none"
                                        key={'linkAct' + i}
                                        color="secondary"
                                        to={{
                                            pathname: '/activities',
                                            autoOpenData: {
                                                isAutoOpenPanel: true,
                                                autoOpenData: act,
                                                previousDataStack: addToStack(),
                                            },
                                        }}>
                                        <Grid
                                            container
                                            item
                                            wrap="nowrap"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            sx={{
                                                backgroundColor: isDarkMode ? 'rgba(0, 0, 0, .3)' : '#F6F6F7',
                                                mb: 2,
                                                p: 2,
                                                pt: 3,
                                                pb: 3,
                                                borderRadius: '16px',
                                            }}>
                                            <Grid item xs={0} sx={{ ml: 1, mr: 1 }}>
                                                <Typography variant="body1">{i + 1}.</Typography>
                                            </Grid>
                                            <Grid item xs zeroMinWidth mr={1}>
                                                <Typography variant="body1">{act.title}</Typography>
                                            </Grid>
                                            <Grid item xs={0} sx={{ mr: 1 }}>
                                                <ArrowForwardIosIcon fontSize="small" color="primary" />
                                            </Grid>
                                        </Grid>
                                    </Link>
                                ))
                            ) : (
                                <Alert
                                    icon={<LinkOffIcon fontSize="inherit" />}
                                    severity="info"
                                    sx={{ borderRadius: '12px', mt: -0.9 }}>
                                    {`No Linked ${store.getState().settings?.displayNames?.activities || 'Loading'}`}
                                </Alert>
                            )}
                        </TabPanel>
                    </Grid>
                </Grid>
                <Dialog open={Boolean(selectedImage)} onClose={handleCloseImage} maxWidth="md" fullWidth>
                    <DialogTitle>Image Preview</DialogTitle>
                    <DialogContent sx={{ textAlign: 'center' }}>
                        {selectedImage && (
                            <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                        )}
                    </DialogContent>
                </Dialog>
            </Grid>
        </Fade>
    )
}
