import React from 'react'
import { ReactComponent as ValueCompendiumIconSvg } from '../../assets/images/value-compendium-logo.svg'
import SvgIcon from '@mui/material/SvgIcon'

type ValueCompendiumIconProps = {
    fontSize?: string
    color?: string
}

const ValueCompendiumIcon = ({ fontSize = '32px', color = 'figma.grayscale.tier1' }: ValueCompendiumIconProps) => {
    return <SvgIcon component={ValueCompendiumIconSvg} inheritViewBox sx={{ fontSize, color }} />
}

export default ValueCompendiumIcon
