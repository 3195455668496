import { useQuery } from 'react-query'
import { useAuth0 } from '../../contexts/auth0-context'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import { ONE_MINUTE } from '../constants/timing'
import { KeyEvidenceStatement } from '../../views/ValueCompendium/queries/useGetValueCompendiumValueNarrativesQuery'

export type SupportingEvidence = {
    _id: string
    name: string
    link: string
    author: string
    citation: string
}

export type OngoingActivity = {
    _id: string
    title: string
}

export type EvidenceGap = {
    _id: string
    text: string
}

export type ValueMessageLinkedData = {
    _id: string
    supportingEvidence: SupportingEvidence[]
    ongoingActivities: OngoingActivity[]
    evidenceGaps: EvidenceGap[]
    keyEvidenceStatements: KeyEvidenceStatement[]
}

export const useGetValueMessageLinkedDataQuery = (valueMessageId: string) => {
    const { getTokenSilently, isAuthenticated, isLoading } = useAuth0()

    const getValueMessageLinkedData = async () => {
        try {
            const token = await getTokenSilently?.({ audience: 'https://atlas.aesara.com' })

            const { url, method } = endpoints.getValueMessageExpandingTableData

            const response = await callAPI(url + valueMessageId, method, token)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    return useQuery<ValueMessageLinkedData>(['valueMessageLinkedData', valueMessageId], getValueMessageLinkedData, {
        enabled: !!getTokenSilently && !!isAuthenticated && !isLoading && !!valueMessageId,

        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,

        cacheTime: ONE_MINUTE,
        staleTime: ONE_MINUTE,
    })
}
