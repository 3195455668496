import Box from '@mui/material/Box'
import { alpha, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import React from 'react'

import { ReactComponent as RightArrowIcon } from '../../../../../assets/images/arrows/right.svg'
import { ReactComponent as CircleIcon } from '../../../../../assets/images/circle.svg'

import TableRow from '@mui/material/TableRow'
import { ValueNarrativeCategory } from '../../../queries/useGetValueNarrativesQuery'
import ValueNarrativeLockIcon from '../../../ValueNarrativeLockIcon/ValueNarrativeLockIcon'
import ValueNarrativeActions from '../../ValueNarrativeActions/ValueNarrativeActions'
import { useValueNarrativeColorBySubpopulation } from '../../../../../views/ValueCompendium/tabs/ValueNarrativesTab/hooks/useValueNarrativeColorBySubpopulation'

type TableViewTabProps = {
    data: ValueNarrativeCategory[]
    handleClickOnValueNarrative: (categoryId: string, valueNarrativeId: string) => void
}

const TableViewTab = ({ data, handleClickOnValueNarrative }: TableViewTabProps) => {
    const { getValueNarrativeColorBySubpopulation } = useValueNarrativeColorBySubpopulation()

    return (
        <Stack gap={1}>
            {data.map((category) => {
                return (
                    <Box key={category._id}>
                        <TableContainer
                            sx={{
                                marginBottom: 3,
                                '.MuiTableRow-root': {
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: 'figma.grayscale.table1',
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: 'figma.grayscale.table2',
                                    },
                                },
                                '.MuiTableCell-root': {
                                    border: 'none',
                                },
                            }}>
                            <Table size="small">
                                <TableHead>
                                    <TableCell />
                                    <TableCell>
                                        <Typography
                                            mb={1.5}
                                            color="figma.grayscale.textHier1"
                                            fontSize="18px !important"
                                            fontWeight={600}>
                                            {category.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell />
                                </TableHead>
                                <TableBody>
                                    {category.narratives.map((narrative, narrativeIndex) => {
                                        const onValueNarrativeClick = () => {
                                            handleClickOnValueNarrative(category._id, narrative._id)
                                        }

                                        const colorBySubpopulation = getValueNarrativeColorBySubpopulation(
                                            narrative.subpopulation
                                        )

                                        return (
                                            <TableRow key={narrative._id}>
                                                <TableCell
                                                    align="left"
                                                    width={'32px'}
                                                    sx={{
                                                        borderTopLeftRadius: narrativeIndex === 0 ? 14 : 0,
                                                        borderBottomLeftRadius:
                                                            narrativeIndex === category.narratives.length - 1 ? 14 : 0,
                                                        paddingRight: 0,
                                                    }}>
                                                    <Typography
                                                        // @ts-expect-error theme type
                                                        variant="body9"
                                                        color="figma.grayscale.tier3">
                                                        {narrativeIndex + 1}.
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" width="100%">
                                                    <Typography
                                                        // @ts-expect-error theme type
                                                        variant="body4"
                                                        color="figma.grayscale.textHier2">
                                                        {narrative.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    sx={{
                                                        borderTopRightRadius: narrativeIndex === 0 ? 14 : 0,
                                                        borderBottomRightRadius:
                                                            narrativeIndex === category.narratives.length - 1 ? 14 : 0,
                                                    }}>
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent="flex-end"
                                                        gap={0.5}>
                                                        {!!narrative.locked && (
                                                            <Stack mr={0.25}>
                                                                <ValueNarrativeLockIcon locked={narrative.locked} />
                                                            </Stack>
                                                        )}
                                                        <Stack mr={0.75}>
                                                            <ValueNarrativeActions valueNarrative={narrative} />
                                                        </Stack>
                                                        <SvgIcon
                                                            fontSize="small"
                                                            component={CircleIcon}
                                                            inheritViewBox
                                                            sx={{
                                                                fontSize: '14px',
                                                                color: colorBySubpopulation,
                                                                '& > circle': {
                                                                    fill: alpha(colorBySubpopulation, 0.1),
                                                                },
                                                            }}
                                                        />
                                                        <IconButton size="large" onClick={onValueNarrativeClick}>
                                                            <SvgIcon
                                                                component={RightArrowIcon}
                                                                inheritViewBox
                                                                fontSize="small"
                                                                color="primary"
                                                                sx={{
                                                                    fontSize: '20px',
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
            })}
        </Stack>
    )
}

export default TableViewTab
