import React, { ComponentProps, ElementType, MouseEvent, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { ClickAwayListener, Fade, Popper, Typography } from '@mui/material'
import { useIsSnapshotMode } from '../../../../shared/hooks/useIsSnapshotMode'
import { useIsReadonlyMode } from '../../../../shared/hooks/useIsReadonlyMode'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as ActionsIcon } from '../../../../assets/images/actions/actions.svg'
import { ReactComponent as LockIcon } from '../../../../assets/images/actions/lock.svg'
// import { ReactComponent as ArchiveIcon } from '../../../../assets/images/actions/archive.svg'
// import { ReactComponent as DuplicateIcon } from '../../../../assets/images/actions/duplicate.svg'
// import { ReactComponent as TransferIcon } from '../../../../assets/images/actions/transfer.svg'
// import { ReactComponent as DeleteIcon } from '../../../../assets/images/actions/delete.svg'
import { useLockValueNarrativeMutation } from '../../../../shared/queries/useLockValueNarrativeMutation'
import { ValueNarrative } from '../../queries/useGetValueNarrativesQuery'
import CircularProgress from '@mui/material/CircularProgress'

type ActionId = 'archive' | 'lock' | 'delete' | 'transfer' | 'duplicate'
type Action = { id: ActionId; icon: ElementType; name: string; handler?: () => void | Promise<void>; loading?: boolean }

type ValueNarrativeActionsProps = Pick<ComponentProps<typeof SvgIcon>, 'sx'> & {
    valueNarrative: ValueNarrative
}

const ValueNarrativeActions = ({ valueNarrative, sx }: ValueNarrativeActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const lockValueNarrativeMutation = useLockValueNarrativeMutation()

    const isSnapshotMode = useIsSnapshotMode()
    const isReadonlyMode = useIsReadonlyMode()

    if (isSnapshotMode || isReadonlyMode) {
        return null
    }

    const actions: Action[] = [
        // {
        //     id: 'duplicate',
        //     name: 'Duplicate',
        //     icon: DuplicateIcon,
        // },
        // {
        //     id: 'transfer',
        //     name: 'Transfer',
        //     icon: TransferIcon,
        // },
        {
            id: 'lock',
            name: !!valueNarrative.locked ? 'Unlock' : 'Lock',
            icon: LockIcon,
            handler: async () => {
                await lockValueNarrativeMutation.mutateAsync(valueNarrative._id)
            },
            loading: lockValueNarrativeMutation.isLoading,
        },
        // {
        //     id: 'archive',
        //     name: 'Archive',
        //     icon: ArchiveIcon,
        // },
        // {
        //     id: 'delete',
        //     name: 'Delete',
        //     icon: DeleteIcon,
        // },
    ]

    const isLoading = actions.some((action) => !!action.loading)

    const handleToggle = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const handleClose = () => {
        if (isLoading) return

        setAnchorEl(null)
    }

    const renderActionRow = (action: Action) => {
        const handleOnClick = async (event: MouseEvent<HTMLElement>) => {
            if (isLoading) return

            await action.handler?.()

            handleToggle(event)
        }

        return (
            <Stack
                onClick={handleOnClick}
                direction="row"
                gap={1.5}
                px={1.5}
                py={0.75}
                alignItems="center"
                sx={{
                    color: action.loading ? 'figma.primary' : 'figma.grayscale.textHier2',
                    '&:hover': {
                        // backgroundColor: 'figma.primary3',
                        color: 'figma.primary',
                    },
                    opacity: isLoading && !action.loading ? 0.4 : 1,
                    cursor: isLoading ? (action.loading ? 'progress' : 'not-allowed') : 'pointer',
                }}>
                <SvgIcon
                    component={action.loading ? () => <CircularProgress size={24} color="primary" /> : action.icon}
                    inheritViewBox
                    fontSize="small"
                    color="inherit"
                    sx={{ fontSize: '24px' }}
                />
                <Typography variant="14x400" color="inherit">
                    {action.name}
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack>
            <IconButton
                onClick={handleToggle}
                size="small"
                sx={{ p: 0.5, color: !!anchorEl ? 'figma.primary' : 'figma.grayscale.tier2' }}>
                <SvgIcon
                    component={ActionsIcon}
                    inheritViewBox
                    fontSize="small"
                    color={'inherit'}
                    sx={[{ fontSize: '20px' }, ...(Array.isArray(sx) ? sx : [sx])]}
                />
            </IconButton>
            <Popper
                disablePortal
                open={!!anchorEl}
                anchorEl={anchorEl}
                placement="bottom-start"
                sx={(theme) => ({ zIndex: theme.zIndex.drawer })}>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Fade {...TransitionProps} in={!!anchorEl} timeout={800} unmountOnExit mountOnEnter>
                            <Stack
                                direction="column"
                                py={0.75}
                                boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.20)"
                                borderRadius="12px"
                                bgcolor="figma.grayscale.tier7"
                                ml={-1.25}>
                                {actions.map((action) => {
                                    return renderActionRow(action)
                                })}
                            </Stack>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </Stack>
    )
}

export default ValueNarrativeActions
