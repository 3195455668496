import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Paper from '@mui/material/Paper'
import HeroImg from '../../assets/images/contact.png'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useAuth0 } from '../../contexts/auth0-context'
import store from '../../redux/store'
import callSecureAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import emailjs from '@emailjs/browser'

export default function HelpCenter() {
    const { getTokenSilently } = useAuth0()
    let [nameField, setNameField] = useState('')
    let [message, setMessage] = useState('')

    async function sendEmail() {
        let data = { user_name: nameField, message: message }
        emailjs.send('service_pwu62xg', 'template_460lr5p', data, '3q_zi_ufoRGoOruZA').then(
            (result) => {
                console.log(result.text)
            },
            (error) => {
                console.log(error.text)
            }
        )
    }

    let [openSubmitFeedbackModal, setOpenSubmitFeedbackModal] = useState(false)
    let [openSubmitFeedbackModal2, setOpenSubmitFeedbackModal2] = useState(false)

    let [feedbackText, setFeedbackText] = useState('')
    let helpDetails =
        store.getState().strategy.organizationId === '603d718dabe119e3a2b22688'
            ? store.getState().settings.faqs
            : [
                  {
                      title: 'Is ATLAS searchable?',
                      details:
                          'Yes! The ATLAS search function makes it possible for you to search messages, activities and evidence using key words.',
                      border: '1px solid #07CC9E',
                  },
                  {
                      title: 'What value does ATLAS bring to my business?',
                      details: (
                          <ul>
                              <li>
                                  <strong>Realize efficiencies:</strong>
                              </li>
                              <li>
                                  Achieve zero waste by targeting organizational resources only toward priorities that
                                  are unfulfilled
                              </li>
                              <li>
                                  Leverage this searchable, intuitive framework for organization of evidence and
                                  activities
                              </li>
                              <li>
                                  <strong>Transform the market:</strong>
                              </li>
                              <li>
                                  Stay on the cutting edge of shifting data and landscapes and evolve the related
                                  strategies accordingly by moving beyond static roadmaps
                              </li>
                              <li>
                                  Understand how different sources of evidence contribute to your goals & spotlight gaps
                                  in priority areas for immediate action
                              </li>
                              <li>
                                  Anticipate and transform the market with timely and relevant information that is
                                  aligned with customer and company priorities
                              </li>
                              <li>
                                  <strong>Move the needle:</strong>
                              </li>
                              <li>
                                  ATLAS can ensure customer focus by connecting all elements of the plan with the
                                  customer messages they support
                              </li>
                              <li>
                                  If evidence generation is guided by the messages that each customer segment needs to
                                  receive, that will make all deliverables more relevant and move the needle for market
                                  access. ATLAS encourages that focus.
                              </li>
                              <li>
                                  <strong>Showcase your success:</strong>
                              </li>
                              <li>
                                  Success is showcased with up-to-date management dashboards and exports to PowerPoint
                                  decks, demonstrating the clear connection of all activities with customer needs and
                                  strategic imperatives.
                              </li>
                          </ul>
                      ),
                      border: '1px solid #FF9C2B',
                  },
                  {
                      title: 'Will ATLAS work with my current systems?',
                      details:
                          'Yes! The ATLAS tool contains links for activities, evidence, and reference materials that can bring you to the places where these materials are currently stored within your organization. We have set it up to work seamlessly with your current systems so that they are not disrupted.',
                      border: '1px solid #C30B31',
                  },
                  {
                      title: 'How do I know the data in ATLAS is secure?',
                      details: (
                          <ul>
                              <li>Data is stored in a secure, limited-access database.</li>
                              <li>
                                  Files are stored and encrypted on AWS with organization-level access restrictions.
                              </li>
                              <li>ARYSANA has completed vulnerability assessments of ATLAS by large pharma.</li>
                              <li>Different user types can be specific (e.g., read-only).</li>
                              <li>
                                  ARYSANA has disaster recovery systems in place to achieve a 48-hour turnaround in
                                  retrieving any data that is lost, as well as a 24-hour cycle for backups in the event
                                  of data loss.
                              </li>
                              <li>
                                  ATLAS also has an archive feature which allows users to manually take ‘snapshots’ and
                                  freeze a read-only version of the site.
                              </li>
                          </ul>
                      ),
                      border: '1px solid #E4349B',
                  },
                  {
                      title: 'Is ATLAS scalable?',
                      details:
                          'ATLAS is easily scalable. There is no cap on the number of users, making it easy for all stakeholders across the organization to use ATLAS.',
                      border: '1px solid #5C34E4',
                  },

                  {
                      title: 'Is consulting and technical support available from ARYSANA?',
                      details: (
                          <ul>
                              <li>
                                  Yes. ARYSANA has deep expertise in global pharmaceutical launch support. We have
                                  biopharma DNA and have successfully launched over 35 pharmaceutical products
                                  worldwide. Currently we are supporting over a dozen more. We have walked in your shoes
                                  and can support your team every step of the way.
                              </li>
                              <li>
                                  Facilitation of workshops and onboarding sessions are included with your ATLAS
                                  license. We can also provide curation of evidence, metadata upload, and roadmap
                                  updates.
                              </li>
                              <li> Over 100 hours of technical support is provided annually at no extra charge.</li>
                          </ul>
                      ),
                      border: '1px solid #5C34E4',
                  },
              ]

    async function submitFeedback() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let body = {
            content: feedbackText,
            user: store.getState().userRecord._id,
            followUp: '',
            strategyId: store.getState().strategy._id,
            organizationId: store.getState().strategy.organizationId,
        }
        callSecureAPI(endpoints.submitFeedback.url, endpoints.submitFeedback.method, token, body)
        setFeedbackText('')
        setOpenSubmitFeedbackModal(true)
    }

    async function submitFeedback2() {
        setOpenSubmitFeedbackModal2(true)
    }

    return (
        <Grid container direction="column">
            <Paper
                elevation={0}
                sx={{
                    backgroundImage: `url(${HeroImg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '25% 70%',
                    minHeight: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 6,
                }}
            />

            {/* Feedback Form and Accordion Container */}
            <Grid container item p={5}>
                <Grid item>
                    <Typography variant="h1">Help Center</Typography>
                </Grid>
                <Grid container item spacing={5}>
                    {/* Feedback Form */}
                    {store.getState().userRecord.organizationId !== '603d718dabe119e3a2b22688' ? (
                        <Grid container item xs direction="column">
                            <Grid item>
                                <Typography variant="h2">Submit Feedback</Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    id="feedback"
                                    type="text"
                                    fullWidth
                                    rows={6}
                                    multiline
                                    placeholder={'Enter text here'}
                                    value={feedbackText}
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    onChange={(e) => setFeedbackText(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={submitFeedback}
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2, borderRadius: '14px', width: 157, height: 52 }}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container item>
                            <Button
                                onClick={submitFeedback2}
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, borderRadius: '14px', width: 157, height: 52 }}>
                                Send Feedback
                            </Button>
                        </Grid>
                    )}

                    {/* Accordion */}
                    <Grid container item direction="column" xs>
                        {helpDetails.map((item, index) => (
                            <Grid item xs>
                                <Accordion
                                    key={index}
                                    elevation={0}
                                    sx={{ borderTop: item.border, background: 'transparent', boxShadow: 'none' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id={`panel1a-header-${index}`}
                                        sx={{ pb: 2, backgroundColor: 'background.default' }}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            {item.title}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ borderTop: 1, borderColor: 'divider' }}>
                                        <Typography variant="body2">{item.details}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            {/* Dialogs for Feedback Submission */}
            <Dialog open={openSubmitFeedbackModal}>
                <DialogTitle>
                    <Typography variant="h2">{`Thank you for your feedback!`}</Typography>
                    <Typography
                        variant="body1"
                        style={{
                            margin: '4px',
                        }}>{`Your input is vital to us. Our team will look at your submission and reach out with any follow-up.`}</Typography>
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenSubmitFeedbackModal(false)} variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openSubmitFeedbackModal2}>
                <DialogTitle>
                    <h2>Send Internal Feedback To Leadership Team</h2>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="feedback"
                        type="text"
                        fullWidth
                        placeholder={'Enter your name'}
                        value={nameField}
                        onFocus={(event) => {
                            event.target.setAttribute('autocomplete', 'off')
                        }}
                        onChange={(e) => setNameField(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        id="feedback"
                        type="text"
                        fullWidth
                        variant="outlined"
                        rows={6}
                        multiline
                        placeholder={'Enter your feedback'}
                        value={message}
                        onFocus={(event) => {
                            event.target.setAttribute('autocomplete', 'off')
                        }}
                        onChange={(e) => setMessage(e.target.value)}
                        InputProps={{
                            style: { borderRadius: 14 },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSubmitFeedbackModal2(false)} variant="outlined">
                        Close
                    </Button>
                    <Button
                        onClick={async () => {
                            setOpenSubmitFeedbackModal2(false)
                            await sendEmail()
                            setMessage('')
                            setNameField('')
                        }}
                        variant="contained">
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
