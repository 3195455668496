import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { SvgIcon } from '@mui/material'
import Tabs from '../../../../shared/components/Tabs/Tabs'

import React, { ComponentProps } from 'react'
import { ReactComponent as TableViewModeIcon } from '../../../../assets/images/ListDashes.svg'
import { ReactComponent as CardsViewModeIcon } from '../../../../assets/images/SquaresFour.svg'

import { ValueNarrativeCategory } from '../../queries/useGetValueNarrativesQuery'
import { EmptyPlaceholder } from '../../../../shared/components/EmptyPlaceholder'
import { useDispatch, useSelector } from 'react-redux'
import { setValueNarrativesViewMode } from '../../../../redux/actions'

import CardsViewTab from './tabs/CardsViewTab'
import TableViewTab from './tabs/TableViewTab'

type Props = {
    categories: ValueNarrativeCategory[]
    handleClickOnValueNarrative: (categoryId: string, valueNarrativeId: string) => void
}

const DEFAULT_VIEW_MODE = 'cards'

export const ValueNarrativesAllCategoriesTabContent = ({ handleClickOnValueNarrative, ...props }: Props) => {
    const viewMode = useSelector(
        // @ts-expect-error redux store is not typed properly
        (state) => state?.valueNarratives?.viewMode ?? DEFAULT_VIEW_MODE
    )

    const dispatch = useDispatch()

    const handleChangeViewMode = (newViewMode: 'cards' | 'table') => {
        dispatch(setValueNarrativesViewMode(newViewMode))
    }

    const getTabLabel = (viewMode: 'table' | 'cards') => {
        const iconComponent = {
            cards: CardsViewModeIcon,
            table: TableViewModeIcon,
        }

        return (
            <SvgIcon
                component={iconComponent[viewMode]}
                inheritViewBox
                fontSize="small"
                sx={{
                    display: 'flex',
                    fontSize: '24px',
                }}
            />
        )
    }

    const data = props.categories.filter((category) => category.narratives?.length > 0)

    const tabs: ComponentProps<typeof Tabs>['tabs'] = [
        {
            id: 'cards',
            label: getTabLabel('cards'),
            view: <CardsViewTab data={data} handleClickOnValueNarrative={handleClickOnValueNarrative} />,
        },
        {
            id: 'table',
            label: getTabLabel('table'),
            view: <TableViewTab data={data} handleClickOnValueNarrative={handleClickOnValueNarrative} />,
        },
    ]

    if (!data.length) {
        return (
            <Box pt={4}>
                <EmptyPlaceholder
                    title="No Value Narratives to display"
                    subtitle="Click 'Add New' on the left panel to create a new Value Narrative"
                />
            </Box>
        )
    }

    return (
        <Tabs
            orientation="horizontal"
            tabs={tabs}
            selectedTabId={viewMode}
            onTabChange={handleChangeViewMode}
            tabPanelSx={{
                pt: 3,
                pl: 3,
            }}
            tabListSx={{
                mr: -3,
            }}
        />
    )
}
