export const COLORS = {
    primary: '#0049EA',
    lipstickRed: '#C30B31',
    salmon: '#FF6B70',
    sunshade: '#FF9C2B',
    brightSun: '#FFD748',
    caribbeanGreen: '#07CC9E',
    screamingGreen: '#80F785',
    magenta: '#E4349B',
    ultraPink: '#E37FFF',
    purpleHeart: '#5C34E4',
    lavender: '#B27FFF',
    butterflyBlue: '#389BFF',
    malibu: '#55B8F9',
    black: '#000000',
    gravel: '#4D4D4D',
    granite: '#808080',
    tuna: '#5E6169',
    pastelGrey: '#CCCCCC',
    greyGoose: '#D3D5DB',
    quillGrey: '#F2F2F4',
    lightQuillGrey: '#F6F6F7',
    white: '#FFFFFF',

    darkPrimary: '#55B8F9',

    sanofiActivityPlanned: '#23004C',
    sanofiActivityInitiated: '#7A00AE',
    sanofiActivityOnHold: '#007FAD',
    sanofiActivityCancelled: '#DA3A16',
    sanofiActivityCompleted: '#268500',
} as const

export type FigmaColorsPalette = typeof FIGMA_LIGHT_COLORS | typeof FIGMA_DARK_COLORS
export type FigmaAccentColor = typeof FIGMA_ACCENT_COLORS[keyof typeof FIGMA_ACCENT_COLORS]

export const FIGMA_ACCENT_COLORS = {
    red: '#C30B31',
    salmon: '#FF6B70',
    orange: '#FF9C2B',
    yellow: '#FFD748',
    green: '#07CC9E',
    neon: '#80F785',
    magenta: '#E4349B',
    pink: '#E37FFF',
    lavender: '#B27FFF',
    purple: '#5C34E4',
    blue: '#389BFF',
    teal: '#55B8F9',
    white: '#FFFFFF',
} as const;

export const FIGMA_LIGHT_COLORS = {
    primary: '#0049EA',
    primaryHover: '#003ABB',
    primary2: '#CCDBFB',
    primary3: '#EBF1FD',
    disabled: '#EBF1FD',
    backgroundOverlay: '#001646',
    backgroundOverlay2: '#001646',
    grayscale: {
        tier1: '#000000',
        tier2: '#4D4D4D',
        tier3: '#808080',
        tier4: '#CCCCCC',
        tier5: '#D3D5DB',
        tier6: '#F2F2F4',
        tier7: '#F6F6F7',
        contentWrapper: '#FFFFFF',
        table1: '#FAFAFA',
        table2: '#F5F5F6',
        frameBackground: '#F2F6FE',
        stroke: '#CCCCCC',
        textHier1: '#000000',
        textHier2: '#4D4D4D',
    },
    accentColors: FIGMA_ACCENT_COLORS,
} as const

export const FIGMA_DARK_COLORS = {
    primary: '#55B8F9',
    primaryHover: '#295A7A',
    primary2: '#38496F',
    primary3: '#363943',
    disabled: '#252626',
    backgroundOverlay: '#172634',
    backgroundOverlay2: '#0F1215',
    grayscale: {
        tier1: '#FFFFFF',
        tier2: '#E5E5E5',
        tier3: '#B9BDC8',
        tier4: '#BBBCBD',
        tier5: '#5E6169',
        tier6: '#3B3F4D',
        tier7: '#1B1C1D',
        contentWrapper: '#161617',
        table1: '#121212',
        table2: '#0F0F0F',
        frameBackground: '#121212',
        stroke: '#3B3F4D',
        textHier1: '#F7F7F7',
        textHier2: '#BBBCBD',
    },
    accentColors: FIGMA_ACCENT_COLORS,
} as const
