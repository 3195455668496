import React, { ComponentProps, ReactElement, ReactNode, useEffect } from 'react'
import { Box, Tabs as MUITabs, Tab as MUITab, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import { ConditionalTooltipWrapper } from '../ConditionalTooltipWrapper'
import { ConditionalWrapper } from '../ConditionalWrapper'

export const TAB_HEIGHT = '42px'

type Tab = {
    id: string
    view: ReactElement
    label?: ReactNode
    disabled?: boolean
    sx?: SxProps<Theme>
}

type TabsProps = {
    tabs: Tab[]
    selectedTabId: string
    onTabChange: (id: string) => void
    sx?: SxProps<Theme>
    tabPanelSx?: SxProps<Theme>
    tabListSx?: SxProps<Theme>
    tabListWrapper?: (tabList: ReactElement) => ReactElement
    orientation?: ComponentProps<typeof MUITabs>['orientation']
}

const TabList = ({
    tabs,
    selectedTabId,
    onTabChange,
    orientation,
    tabListSx,
    tabListWrapper,
}: Omit<TabsProps, 'sx' | 'tabPanelSx'>) => (
    <ConditionalWrapper condition={!!tabListWrapper} wrapper={tabListWrapper}>
        <MUITabs
            orientation={orientation}
            variant="scrollable"
            scrollButtons="auto"
            value={selectedTabId}
            onChange={(_, newValue) => onTabChange(newValue)}
            sx={[
                {
                    minHeight: TAB_HEIGHT,
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                    '& .MuiTabs-scrollButtons': {
                        height: TAB_HEIGHT,
                    },
                    ...(orientation === 'vertical'
                        ? {
                              borderRight: 1,
                              borderColor: 'figma.grayscale.stroke',
                              width: 'max-content',
                              minWidth: 'max-content',
                          }
                        : {}),
                    ...(orientation === 'horizontal'
                        ? {
                              position: 'relative',
                              '&::before': {
                                  content: '" "',
                                  position: 'absolute',
                                  bottom: 0,
                                  height: 0,
                                  width: '100%',
                                  borderBottom: 1,
                                  borderColor: 'figma.grayscale.stroke',
                              },
                          }
                        : {}),
                },
                ...(Array.isArray(tabListSx) ? tabListSx : [tabListSx]),
            ]}>
            {tabs.map((tab) => (
                <MUITab
                    key={tab.id}
                    value={tab.id}
                    disabled={tab.disabled}
                    label={
                        <>
                            <div>
                                <span className="unselected">
                                    <ConditionalTooltipWrapper
                                        condition={tab.disabled}
                                        title="Coming soon..."
                                        enterDelay={600}
                                        enterNextDelay={600}
                                        arrow
                                        sx={{ pointerEvents: 'all', cursor: 'not-allowed' }}>
                                        <>{tab.label ?? tab.id}</>
                                    </ConditionalTooltipWrapper>
                                </span>
                                <span className="selected">{tab.label ?? tab.id}</span>
                            </div>
                        </>
                    }
                    wrapped
                    disableRipple
                    sx={{
                        height: TAB_HEIGHT,
                        maxHeight: TAB_HEIGHT,
                        minHeight: TAB_HEIGHT,
                        borderTop: '1px solid',
                        borderTopColor: 'transparent !important',
                        borderBottom: '1px solid',
                        borderColor: 'figma.grayscale.stroke',
                        fontSize: '16px',
                        fontWeight: '400',
                        fontFamily: 'acumin-pro !important',
                        textTransform: 'capitalize',
                        color: 'figma.grayscale.textHier2',
                        paddingInline: '12px',
                        paddingBlock: '4px',
                        ...(orientation === 'vertical'
                            ? {
                                  minWidth: '220px',
                                  maxWidth: '25vw',
                              }
                            : {
                                  minWidth: 'unset',
                              }),
                        ' > div': {
                            height: '100%',
                            // width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            ' > span': {
                                paddingInline: '12px',
                                fontWeight: 400,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                '&.selected': {
                                    position: 'absolute',
                                    fontWeight: 600,
                                },
                            },
                        },
                        '&:not(.Mui-selected)': {
                            ' > div': {
                                ' > span': {
                                    '&.selected': {
                                        display: 'none',
                                    },
                                },
                            },
                        },
                        '&.Mui-selected': {
                            borderTopWidth: '2px',
                            borderBottomWidth: '2px',
                            borderColor: 'figma.primary',
                            lineHeight: '24px',
                            ' > div': {
                                backgroundColor: 'figma.primary2',
                                ' > span': {
                                    '&.unselected': {
                                        opacity: 0,
                                    },
                                },
                            },
                        },
                        '&.Mui-disabled': {
                            ' > div': {
                                ' > span': {
                                    color: 'figma.grayscale.tier5',
                                },
                            },
                        },
                    }}
                />
            ))}
        </MUITabs>
    </ConditionalWrapper>
)

const TabPanel = ({ tabs, selectedTabId, tabPanelSx }: Omit<TabsProps, 'onTabChange' | 'sx' | 'tabListSx'>) => {
    const selectedTab = tabs.find((tab) => tab.id === selectedTabId)

    if (!selectedTab) {
        return null
    }

    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            sx={[
                ...(Array.isArray(tabPanelSx) ? tabPanelSx : [tabPanelSx]),
                ...(Array.isArray(selectedTab.sx) ? selectedTab.sx : [selectedTab.sx]),
            ]}>
            {selectedTab.view}
        </Box>
    )
}

const Tabs = ({
    tabs,
    selectedTabId,
    onTabChange,
    sx = [],
    tabPanelSx = [],
    tabListSx = [],
    tabListWrapper,
    orientation = 'vertical',
}: TabsProps) => {
    useEffect(() => {
        const validSelectedTab = tabs.find((tab) => tab.id === selectedTabId && !tab.disabled)
        if (!validSelectedTab) {
            const firstNonDisabledTab = tabs.find((tab) => !tab.disabled)
            if (firstNonDisabledTab) {
                onTabChange(firstNonDisabledTab.id)
            }
        }
    }, [selectedTabId, tabs, onTabChange])

    return (
        <Stack flex={1} display="flex" sx={sx} direction={orientation === 'vertical' ? 'row' : 'column'}>
            <TabList
                tabs={tabs}
                selectedTabId={selectedTabId}
                onTabChange={onTabChange}
                orientation={orientation}
                tabListSx={tabListSx}
                tabListWrapper={tabListWrapper}
            />
            <TabPanel tabs={tabs} selectedTabId={selectedTabId} tabPanelSx={tabPanelSx} />
        </Stack>
    )
}

export default Tabs
