import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import { SvgIcon, Typography } from '@mui/material'
import React, { ComponentProps } from 'react'
import { ReactComponent as LockIcon } from '../../../assets/images/actions/lock.svg'
import { ValueNarrative } from '../queries/useGetValueNarrativesQuery'

type ValueNarrativeLockIconProps = Pick<ComponentProps<typeof SvgIcon>, 'sx'> & {
    locked: ValueNarrative['locked']
}

const ValueNarrativeLockIcon = ({ sx, locked }: ValueNarrativeLockIconProps) => {
    return (
        <Tooltip
            placement="top-start"
            arrow
            title={
                <Stack gap={1}>
                    <Typography
                        variant="14x400"
                        color="figma.grayscale.textHier1"
                        style={{
                            whiteSpace: 'pre-line',
                        }}>
                        Locked by{' '}
                        <Typography variant="14x600" color="figma.grayscale.textHier1">
                            {locked.lockedBy.name}
                        </Typography>
                        ,{'\n'}{' '}
                        {new Date(locked.lockedAt).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}
                    </Typography>
                </Stack>
            }
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: 'figma.grayscale.contentWrapper',
                        px: 2,
                        py: 0.5,
                        borderRadius: '6px',
                        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.20);',
                    },
                },
                arrow: {
                    sx: {
                        color: 'figma.grayscale.contentWrapper',
                    },
                },
            }}>
            <SvgIcon
                component={LockIcon}
                inheritViewBox
                fontSize="small"
                color="primary"
                sx={[
                    {
                        fontSize: '20px',
                        cursor: 'help',
                    },
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
            />
        </Tooltip>
    )
}

export default ValueNarrativeLockIcon
