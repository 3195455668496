import Box from '@mui/material/Box'
import CreateButton from '../../../Buttons/CreateButton'
import VMTable from '../../../ValueMessages/VMTable'
import React from 'react'
import { ValueNarrativeSelector } from '../ValueNarrativeSelector/ValueNarrativeSelector'
import Stack from '@mui/material/Stack'
import { ValueNarrative, ValueNarrativeCategory } from '../../queries/useGetValueNarrativesQuery'
import { useAddValueMessageMutation } from '../../queries/useAddValueMessageMutation'
import { useInvalidateGetValueNarrativesQuery } from '../../queries/useInvalidateGetValueNarrativesQuery'
import { useValueNarrativeColorBySubpopulation } from '../../../../views/ValueCompendium/tabs/ValueNarrativesTab/hooks/useValueNarrativeColorBySubpopulation'

type Props = {
    isAIEnabled: boolean
    selectedCategory: ValueNarrativeCategory
    selectedNarrative: ValueNarrative
    handleSelectNarrative: (categoryId: string, narrativeId: string) => void
}

export const ValueNarrativeCategoryTabContent = ({
    isAIEnabled,
    selectedCategory,
    selectedNarrative,
    handleSelectNarrative,
}: Props) => {
    const createVMButtonRef = React.useRef(null)

    const { getValueNarrativeColorBySubpopulation } = useValueNarrativeColorBySubpopulation()

    const colorBySubpopulation = getValueNarrativeColorBySubpopulation(selectedNarrative?.subpopulation)

    const handleCreateButtonClick = () => {
        createVMButtonRef.current?.handleClickOpen()
    }

    const { invalidateGetValueNarrativesQuery } = useInvalidateGetValueNarrativesQuery()

    const { mutate, isLoading } = useAddValueMessageMutation()

    const handleCreateValueMessage = (valueMessage: object) => {
        mutate({ ...valueMessage, categoryId: selectedCategory._id, narrativeId: selectedNarrative._id })
    }

    return (
        <Stack>
            <ValueNarrativeSelector
                isAIEnabled={isAIEnabled}
                colorBySubpopulation={colorBySubpopulation}
                selectedCategory={selectedCategory}
                selectedNarrative={selectedNarrative}
                handleSelectNarrative={handleSelectNarrative}
            />
            <>
                <Box position="absolute" zIndex={-1} visibility="hidden">
                    <CreateButton
                        ref={createVMButtonRef}
                        // @ts-expect-error tight deadline...
                        id="vm"
                        createNew={handleCreateValueMessage}
                        loading={isLoading}
                    />
                </Box>
                <VMTable
                    handleCreateButtonClick={handleCreateButtonClick}
                    valueMessages={selectedNarrative.valueMessages}
                    onUpdate={invalidateGetValueNarrativesQuery}
                    colorBySubpopulation={colorBySubpopulation}
                />
            </>
        </Stack>
    )
}
