import {
    useGetValueNarrativesQuery,
    ValueNarrative,
    ValueNarrativeCategory,
} from './queries/useGetValueNarrativesQuery'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedValueNarrative, setSelectedValueNarrativeCategory } from '../../redux/actions'
import { useIsMarketMode } from '../../shared/hooks/useIsMarketMode'
import { useEffect, useMemo } from 'react'

export const useValueNarratives = () => {
    // @ts-expect-error redux store is not typed properly
    const strategyId = useSelector((state) => state.strategy?._id)

    const dispatch = useDispatch()

    const selectedCategories = useSelector(
        // @ts-expect-error redux store is not typed properly
        (state) => state?.valueNarratives?.selectedCategories
    )
    const selectedNarratives = useSelector(
        // @ts-expect-error redux store is not typed properly
        (state) => state?.valueNarratives?.selectedNarratives
    )

    const selectedCategoryId = selectedCategories?.[strategyId] || {}
    const selectedNarrativesIds = selectedNarratives?.[strategyId] || {}
    const selectedNarrativeId = selectedNarratives?.[strategyId]?.[selectedCategoryId] || {}

    const handleSelectNarrative = (categoryId: string, narrativeId: string) => {
        dispatch(setSelectedValueNarrative({ strategyId, categoryId, narrativeId }))
    }

    const handleSelectCategory = (categoryId: string) => {
        dispatch(setSelectedValueNarrativeCategory({ strategyId, categoryId }))

        if (!selectedNarrativesIds[categoryId]) {
            const category = categories?.find((category) => category._id === categoryId)
            handleSelectNarrative(categoryId, category?.narratives[0]?._id || null)
        }
    }

    const { data, isLoading } = useGetValueNarrativesQuery()

    const { selectedMarket, isMarketMode } = useIsMarketMode()

    const categories = useMemo(() => {
        // @TODO do I need to do .reverse() as is used to be?
        return (data || []).map((category) => ({
            ...category,
            narratives: category.narratives.map((narrative) => {
                return {
                    ...narrative,
                    valueMessages: narrative.valueMessages
                        .map((message) => {
                            if (!message) {
                                return null
                            }

                            if (isMarketMode) {
                                if (message.localMarkets.includes(selectedMarket)) {
                                    return message
                                }
                            } else {
                                if (!message.isCountrySpecific) {
                                    return message
                                }
                            }

                            return null
                        })
                        .filter((message) => message !== null),
                }
            }),
        }))
    }, [data, isMarketMode, selectedMarket])

    const selectedCategory: ValueNarrativeCategory =
        selectedCategoryId === 'All'
            ? {
                  _id: 'All',
                  name: 'All',
                  narratives: [],
                  order: 0,
              }
            : categories?.find((category) => category._id === selectedCategoryId)

    const isSelectedCategoryNeedsToBeReset = !!data && !selectedCategory?._id

    useEffect(() => {
        if (isSelectedCategoryNeedsToBeReset) {
            handleSelectCategory('All')
        }
        return () => {
            handleSelectCategory('All')
        }
    }, [isSelectedCategoryNeedsToBeReset])

    const selectedNarrative: ValueNarrative | null = selectedCategory?.narratives.find(
        (narrative) => narrative._id === selectedNarrativeId
    )

    const isSelectedNarrativeNeedsToBeReset =
        !!data && !!selectedCategory?._id && selectedCategory?._id !== 'All' && !selectedNarrative?._id

    useEffect(() => {
        if (isSelectedNarrativeNeedsToBeReset) {
            handleSelectNarrative(selectedCategory?._id, selectedCategory?.narratives?.[0]?._id || null)
        }
    }, [isSelectedNarrativeNeedsToBeReset, selectedCategory])

    const isCategoriesDataReady = !!selectedCategory && !isLoading
    const isAllCategorySelected = selectedCategory?._id === 'All'

    return {
        categories,

        selectedCategory,
        handleSelectCategory,

        selectedNarrative,
        handleSelectNarrative,

        isCategoriesDataReady,
        isAllCategorySelected,
    }
}
