import Typography from '@mui/material/Typography'
import { alpha, SvgIcon } from '@mui/material'
import React from 'react'
import { ReactComponent as CircleIcon } from '../../../../../assets/images/circle.svg'
import Stack from '@mui/material/Stack'
import { useGetSettingsQuery } from '../../../../../shared/queries/useGetSettingsQuery'
import { useValueNarrativeColorBySubpopulation } from '../hooks/useValueNarrativeColorBySubpopulation'

const renderColumns = (subpopulations) => {
    const half = Math.ceil(subpopulations.length / 2)
    return [subpopulations.slice(0, half), subpopulations.slice(half)]
}

const ValueNarrativesLegend = () => {
    const { data, isLoading } = useGetSettingsQuery()

    const { getValueNarrativeColorBySubpopulation } = useValueNarrativeColorBySubpopulation()

    const SubpopulationItem = ({ subpopulation }) => {
        const colorBySubpopulation = getValueNarrativeColorBySubpopulation(subpopulation)

        return (
            <Stack direction="row" gap={1.25} alignItems="center">
                <SvgIcon
                    fontSize="small"
                    component={CircleIcon}
                    inheritViewBox
                    sx={{
                        fontSize: '16px',
                        color: colorBySubpopulation,
                        '& > circle': {
                            fill: alpha(colorBySubpopulation, 0.1),
                        },
                    }}
                />
                <Typography variant="16x500" whiteSpace="nowrap">
                    {subpopulation}
                </Typography>
            </Stack>
        )
    }

    if (isLoading || !data?.vmSubpopulations || data.vmSubpopulations.length === 0) {
        return null
    }

    const columns = renderColumns(data.vmSubpopulations)

    return (
        <Stack direction="column" flex={1}>
            <Typography variant="18x600" sx={{ mb: 1.5 }}>
                Subpopulations
            </Typography>
            <Stack direction="column" gap={{ xs: 1, md: 1.25 }} p={2} borderRadius={4} bgcolor="figma.grayscale.tier7">
                <Stack direction={{ xs: 'column', md: 'row' }} rowGap={1} columnGap={12}>
                    <Stack direction="column" gap={1.25} flex={1}>
                        {columns[0].map((subpopulation, index) => (
                            <SubpopulationItem key={index} subpopulation={subpopulation} />
                        ))}
                    </Stack>

                    <Stack direction="column" gap={1.25} flex={1}>
                        {columns[1].map((subpopulation, index) => (
                            <SubpopulationItem key={index} subpopulation={subpopulation} />
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ValueNarrativesLegend
