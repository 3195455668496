import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { useAuth0 } from '../../contexts/auth0-context'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import { ValueNarrativeSubpopulation } from '../../components/ValueNarrativesView/queries/useGetValueNarrativesQuery'

const ONE_MINUTE = 1000 * 60
const FIVE_MINUTES = ONE_MINUTE * 5

export type Settings = {
    vmSubpopulations: ValueNarrativeSubpopulation[]
}

type UseGetSettingsQueryProps = {
    onGetSettings?: (settings: Settings) => void
}

export const useGetSettingsQuery = ({ onGetSettings }: UseGetSettingsQueryProps = {}) => {
    const { getTokenSilently, isAuthenticated, isLoading } = useAuth0()

    // @ts-expect-error store is not typed properly
    const organizationId = useSelector((state) => state.userRecord?.organizationId)

    const getSettings = async () => {
        try {
            const token = await getTokenSilently?.({ audience: 'https://atlas.aesara.com' })

            const { url, method } = endpoints.getSettingsByOrganizationId

            const response = await callAPI(url + organizationId, method, token)

            return response.data
        } catch (e) {
            console.log(e)
        }
    }

    return useQuery<Settings>(['settings', organizationId], getSettings, {
        enabled: !!getTokenSilently && !!isAuthenticated && !isLoading,
        cacheTime: FIVE_MINUTES,
        staleTime: FIVE_MINUTES,
        onSuccess: onGetSettings,
    })
}
