import { Chip } from '../Chip/Chip'
import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'
import { ReactComponent as CircleIcon } from '../../../assets/images/circle.svg'
import { alpha, MenuItem, SelectChangeEvent } from '@mui/material'
import { ValueNarrative } from '../../../components/ValueNarrativesView/queries/useGetValueNarrativesQuery'
import { useGetSettingsQuery } from '../../queries/useGetSettingsQuery'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import { useBooleanState } from '../../hooks/useBooleanState'
import { useUpdateValueNarrativeSubpopulationMutation } from '../../queries/useUpdateValueNarrativeSubpopulationMutation'
import { useValueNarrativeColorBySubpopulation } from '../../../views/ValueCompendium/tabs/ValueNarrativesTab/hooks/useValueNarrativeColorBySubpopulation'
import { useIsSnapshotMode } from '../../hooks/useIsSnapshotMode'
import { useIsReadonlyMode } from '../../hooks/useIsReadonlyMode'

type SubpopulationSelectorProps = {
    valueNarrative: ValueNarrative
}

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '16px !important',
        backgroundColor: `${theme.palette.figma.grayscale.tier7} !important`,
        boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.17); !important',
        transition: 'none !important',
        maxHeight: '300px !important',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(1.5)} !important`,
        padding: `${theme.spacing(2)} !important`,
        '& > li': {
            minHeight: 'auto !important',
        },
    },
}))

const SubpopulationSelector = ({ valueNarrative }: SubpopulationSelectorProps) => {
    const styles = useStyles()
    const open = useBooleanState()

    const { data, isLoading } = useGetSettingsQuery()
    const { mutateAsync } = useUpdateValueNarrativeSubpopulationMutation()

    const { getValueNarrativeColorBySubpopulation } = useValueNarrativeColorBySubpopulation()

    const isSnapshotMode = useIsSnapshotMode()
    const isReadonlyMode = useIsReadonlyMode()

    if (!valueNarrative?.subpopulation) {
        return null
    }

    const options = (data?.vmSubpopulations || []).map((subpopulation) => ({
        id: subpopulation,
        label: subpopulation,
    }))

    const color = getValueNarrativeColorBySubpopulation(valueNarrative?.subpopulation)

    const handleChange = async (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as string

        const subpopulation = data.vmSubpopulations.find((subpopulation) => subpopulation === value)
        await mutateAsync({ ...valueNarrative, subpopulation })
    }

    const renderValue = () => {
        return (
            <Chip
                hoverable
                color={open.state ? 'figma.primary' : 'figma.grayscale.tier2'}
                backgroundColor={alpha(color, 0.1)}
                icon={
                    <SvgIcon
                        component={CircleIcon}
                        inheritViewBox
                        fontSize="small"
                        sx={(theme) => ({
                            fontSize: '16px',
                            color: color || theme.palette.figma.grayscale.tier4,
                            '& > circle': {
                                fill: alpha(color || theme.palette.figma.grayscale.tier4, 0.1),
                            },
                        })}
                    />
                }
                name={valueNarrative?.subpopulation}
            />
        )
    }

    const isDisabled =
        isSnapshotMode ||
        isReadonlyMode ||
        !options.length ||
        (options.length === 1 && options[0].id === valueNarrative?.subpopulation) ||
        !!valueNarrative?.locked ||
        isLoading

    return (
        <Select
            disabled={isDisabled}
            MenuProps={{
                classes: {
                    paper: styles.paper,
                    list: styles.list,
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            }}
            open={open.state}
            onClose={open.handleSetAsFalse}
            onOpen={open.handleSetAsTrue}
            variant="outlined"
            value={valueNarrative?.subpopulation}
            onChange={handleChange}
            displayEmpty
            renderValue={renderValue}
            IconComponent={null}
            sx={(theme) => ({
                borderRadius: '16px',
                '& .MuiOutlinedInput-notchedOutline': {
                    border: `unset !important`,
                },
                '& .MuiOutlinedInput-input': {
                    display: 'flex',
                    p: `${theme.spacing(0.5)} !important`,
                    '&.Mui-disabled': {
                        cursor: 'not-allowed',
                    },
                },
                '& .MuiSelect-icon': {
                    color: `${theme.palette.figma.primary} !important`,
                },
            })}>
            {options.map((option) => {
                const isSelected = valueNarrative?.subpopulation === option.id

                return (
                    <MenuItem
                        key={option.id}
                        selected={isSelected}
                        value={option.id}
                        disableRipple
                        sx={(theme) => ({
                            flex: 1,
                            p: 0,
                            borderRadius: '8px',
                            color: isSelected ? 'figma.primary' : 'figma.grayscale.textHier2',
                            backgroundColor: isSelected
                                ? `${theme.palette.figma.primary3} !important`
                                : 'unset !important',
                            mx: isSelected ? -1 : 0,
                            my: isSelected ? -0.5 : 0,
                            px: isSelected ? 1 : 0,
                            py: isSelected ? 0.5 : 0,
                            '&:hover': {
                                color: 'figma.primary',
                            },
                        })}>
                        <Stack direction="row" gap={1}>
                            <Typography variant="16x500" color="inherit" sx={{ mt: -0.25 }}>
                                {option.label}
                            </Typography>
                        </Stack>
                    </MenuItem>
                )
            })}
        </Select>
    )
}

export default SubpopulationSelector
