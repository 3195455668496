import React, { ComponentProps } from 'react'
import Box from '@mui/material/Box'
import { ValueNarrativeCategoryTabContent } from './ValueNarrativeCategoryTabContent'
import { ValueNarrativesAllCategoriesTabContent } from './ValueNarrativesAllCategoriesTabContent'
import { ValueNarrative, ValueNarrativeCategory } from '../../queries/useGetValueNarrativesQuery'
import { EmptyPlaceholder } from '../../../../shared/components/EmptyPlaceholder'
import Tabs from '../../../../shared/components/Tabs/Tabs'

type Props = {
    isAIEnabled: boolean
    categories: ValueNarrativeCategory[]
    selectedCategory: ValueNarrativeCategory
    selectedNarrative: ValueNarrative | null
    handleSelectCategory: (categoryId: string) => void
    handleSelectNarrative: (categoryId: string, narrativeId: string) => void
}

export const ValueNarrativeCategoryTabsWrapper = ({
    isAIEnabled,
    selectedCategory,
    selectedNarrative,
    handleSelectCategory,
    handleSelectNarrative,
    categories,
}: Props) => {
    const handleClickOnValueNarrative = (categoryId: string, narrativeId: string) => {
        handleSelectCategory(categoryId)
        handleSelectNarrative(categoryId, narrativeId)
    }

    const getNumberOfNarrativesPerCategory = (categoryId: string) => {
        if (categoryId === 'All') {
            return categories.reduce((acc, item) => acc + item.narratives.length, 0)
        }
        return categories.find((category) => category._id === categoryId)?.narratives?.length
    }

    const tabs: ComponentProps<typeof Tabs>['tabs'] = [
        {
            id: 'All',
            label: `All (${getNumberOfNarrativesPerCategory('All')})`,
            view: (
                <ValueNarrativesAllCategoriesTabContent
                    categories={categories}
                    handleClickOnValueNarrative={handleClickOnValueNarrative}
                />
            ),
        },
        ...categories
            .sort((x, y) => x.order - y.order)
            .map((category) => ({
                id: category._id,
                label: `${category.name} (${getNumberOfNarrativesPerCategory(category._id)})`,
                sx: {
                    pl: 3,
                    pb: 3
                },
                view: selectedNarrative ? (
                    <ValueNarrativeCategoryTabContent
                        isAIEnabled={isAIEnabled}
                        selectedCategory={selectedCategory}
                        selectedNarrative={selectedNarrative}
                        handleSelectNarrative={handleSelectNarrative}
                    />
                ) : (
                    <Box pt={4}>
                        <EmptyPlaceholder
                            title="No Value Narratives to display"
                            subtitle="Click 'Add New' on the left panel to create a new Value Narrative"
                        />
                    </Box>
                ),
            })),
    ]

    return (
        <Box display="flex" flex={1} ml={-2}>
            <Tabs
                tabs={tabs}
                selectedTabId={selectedCategory._id}
                onTabChange={handleSelectCategory}
            />
        </Box>
    )
}
