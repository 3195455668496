import React, { useState } from 'react'
import { withStyles, styled } from '@mui/styles'
import TableRow from '@mui/material/TableRow'
import { Checkbox, Dialog } from '@mui/material/'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { green, red, yellow } from '@mui/material/colors'
import Radio from '@mui/material/Radio'
import BoxGraphic from '../../assets/images/unfilledpages.png'

// Area to create custom elements or Material UI components

export const WhiteOutlineCheckbox = withStyles({
    checked: {},
})((props) => <Checkbox color="default" value={props.val} {...props} />)

export const BlueCheckBox = withStyles({
    checked: {},
})((props) => <Checkbox color="default" value={props.val} {...props} />)

export const RedRadio = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />)

export const YellowRadio = withStyles({
    root: {
        color: yellow[400],
        '&$checked': {
            color: yellow[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />)

export const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />)

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.figma.grayscale.table1,
    },
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.figma.grayscale.table2,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#0049EA',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#0049EA',
    },
}))

export function useHover() {
    const [hover, setHover] = useState(false)
    const mouseEvents = {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
    }
    return [hover, mouseEvents]
}

export const BootstrapDialog = styled(Dialog)(({ theme, isDarkMode }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        background: 'transparent',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        minHeight: '250px',
        backgroundImage: `url(${BoxGraphic})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundPositionX: '90%',
        border: '2px solid #0049EA',
        backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.9)' : 'rgba(255, 255, 255, 0.9)',
    },
}))
