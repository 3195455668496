import Box from '@mui/material/Box'
import { alpha, SvgIcon, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import React from 'react'

import { ReactComponent as RightArrowIcon } from '../../../../../assets/images/arrows/right.svg'
import ValueNarrativeLockIcon from '../../../ValueNarrativeLockIcon/ValueNarrativeLockIcon'
import ValueNarrativeActions from '../../ValueNarrativeActions/ValueNarrativeActions'
import { ValueNarrativeCategory } from '../../../queries/useGetValueNarrativesQuery'
import { useValueNarrativeColorBySubpopulation } from '../../../../../views/ValueCompendium/tabs/ValueNarrativesTab/hooks/useValueNarrativeColorBySubpopulation'

type CardsViewTabProps = {
    data: ValueNarrativeCategory[]
    handleClickOnValueNarrative: (categoryId: string, valueNarrativeId: string) => void
}

const CardsViewTab = ({ data, handleClickOnValueNarrative }: CardsViewTabProps) => {
    const { getValueNarrativeColorBySubpopulation } = useValueNarrativeColorBySubpopulation()

    return (
        <Stack gap={1} direction="column">
            {data.map((category) => {
                return (
                    <Box key={category._id}>
                        <Typography
                            mb={1}
                            color="figma.grayscale.textHier1"
                            fontSize="18px !important"
                            fontWeight={400}>
                            {category.name}
                        </Typography>
                        <Box
                            display="grid"
                            gridTemplateColumns={{
                                sm: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                                lg: 'repeat(3, 1fr)',
                            }}
                            gridAutoRows="minmax(80px, auto)"
                            gap={3}
                            pb={2}>
                            {category.narratives.map((narrative) => {
                                const colorBySubpopulation = getValueNarrativeColorBySubpopulation(
                                    narrative.subpopulation
                                )

                                const onValueNarrativeClick = () => {
                                    handleClickOnValueNarrative(category._id, narrative._id)
                                }

                                return (
                                    <Box
                                        key={narrative._id}
                                        p={2}
                                        pb={1.5}
                                        position="relative"
                                        sx={(theme) => ({
                                            backgroundColor: `${alpha(colorBySubpopulation, 0.1)} !important`,
                                            borderColor: colorBySubpopulation,
                                            borderRadius: '12px',
                                            borderStyle: 'solid',
                                            borderWidth: '1px',
                                        })}>
                                        <Typography
                                            mb={1}
                                            fontSize="16px !important"
                                            fontWeight={400}
                                            color="figma.grayscale.textHier2"
                                            sx={{
                                                paddingRight: 6,
                                                overflow: 'auto',
                                                maxHeight: '350px',
                                            }}>
                                            {narrative.name}
                                        </Typography>
                                        {!!narrative.locked && (
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="flex-end"
                                                position="absolute"
                                                top="12px"
                                                right="12px">
                                                <ValueNarrativeLockIcon
                                                    locked={narrative.locked}
                                                    sx={{
                                                        fontSize: '24px',
                                                    }}
                                                />
                                            </Stack>
                                        )}
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            position="absolute"
                                            bottom="12px"
                                            right="12px">
                                            <ValueNarrativeActions valueNarrative={narrative} />
                                            <IconButton size="small" onClick={onValueNarrativeClick}>
                                                <SvgIcon
                                                    component={RightArrowIcon}
                                                    inheritViewBox
                                                    fontSize="small"
                                                    color="primary"
                                                    sx={{
                                                        fontSize: '16px',
                                                    }}
                                                />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                )
            })}
        </Stack>
    )
}

export default CardsViewTab
