import { useQueryClient } from 'react-query'

export const useInvalidateGetSettingsQuery = () => {
    const queryClient = useQueryClient()

    const invalidateGetSettingsQuery = async () => {
        await queryClient.invalidateQueries('settings')
    }

    return { invalidateGetSettingsQuery }
}
